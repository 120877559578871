import React, { useState } from 'react';
import './OSlider.css'; // Import CSS file for styling

const OSlider = ({ min, max, defaultValue }) => {
  const [value, setValue] = useState(defaultValue);
  const [dragging, setDragging] = useState(false);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleMouseDown = (event) => {
    setDragging(true);
    setValue(event.target.value);

    const handleMouseMove = (event) => {
      if (dragging) {
        setValue(event.target.value);
      }
    };

    const handleMouseUp = () => {
      setDragging(false);
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  };

  return (
    <div className="slider-container">
      <input
        type="range"
        min={min}
        max={max}
        value={value}
        onChange={handleChange}
        onMouseDown={handleMouseDown}
        className="slider-input"
      />
      <span className="slider-value">{value}</span>
    </div>
  );
};

export default OSlider;
