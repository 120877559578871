import React, { Component, useState } from 'react';
import { Button, Container, Select, TextField, Typography , MenuItem } from '@mui/material';
import OnshoPageContainer from './OnshoPageContainer';

class FactureForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      facture: {
        // Informations sur l'entrepreneur individuel
        identiteEntrepreneur: '',
        raisonSociale: '',
        adresseFacturation: '',
        numeroSIREN: '',
        numeroSIRET: '',
        numeroRM: '',
        formeJuridique: '',
        greffeImmatriculation: '',
        // Informations sur le client
        identiteClient: '',
        adresseClient: '',
        raisonSocialeClient: '',
        numeroTVA: '',
        // Informations sur la facture
        dateEmission: '',
        numeroFacture: '',
        dateVentePrestation: '',
        designationPrestation: '',
        prixUnitaireHT: '',
        tauxTVA: '',
        montantTVA: '',
        reductions: '',
        montantTotal: ''
      },
      users: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  
  async handleSubmit(event) {
    event.preventDefault();
    const userInfo = localStorage.getItem('userInfo');
    const token = localStorage.getItem('token');
  
    if (!userInfo) {
      alert('Vous devez être connecté pour enregistrer une facture.');
      return;
    }
    const user = JSON.parse(userInfo);
    const userId = this.state.facture.identiteClient; // Récupérer l'ID du client sélectionné
    const facture = {
      ...this.state.facture,
      identiteClient: userId // Utiliser l'ID du client sélectionné
    };
  
    try {
      const response = await fetch(`/b/add-facture/${userId}`, { // Utiliser l'ID du client dans l'URL de la requête
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(facture),
      });
      if (response.ok) {
        alert('Facture enregistrée avec succès.');
      } else {
        alert('Erreur lors de l\'enregistrement de la facture.');
      }
    } catch (error) {
      alert('Erreur lors de la connexion au serveur.');
    }
  }
  

  handleChange(event) {
    const { name, value } = event.target;
    this.setState(prevState => ({
      facture: {
        ...prevState.facture,
        [name]: value
      }
    }));
  }
  async fetchUsers() {
    try {
      const token = localStorage.getItem('token'); // Ajoutez cette ligne pour récupérer le jeton JWT

      const response = await fetch('/b/get-users', {
        method: 'GET',
        headers: {
          'Content-Type': 'application',
          'Authorization': `Bearer ${token}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({users: data});
      } else {
        this.setState({error: 'Erreur lors de la récupération des utilisateurs.'});
      }
    } catch (error) {
      this.setState({error: 'Erreur lors de la connexion au serveur.'});
    }
  }
  componentDidMount() {
    this.fetchUsers();
  }

  render() {
    const { facture } = this.state;
    return (
      <OnshoPageContainer component="main">
        <Typography variant="h4">Facture</Typography>
        <form onSubmit={this.handleSubmit}>
        <TextField
  select
  label="Client"
  value={facture.identiteClient || ''}
  name="identiteClient"
  onChange={this.handleChange}
  margin="normal"
  fullWidth
>
  {this.state.users.map((user, index) => (
    <MenuItem key={index} value={user._id}>
      {user.name} - {user.email}
    </MenuItem>
  ))}
</TextField>

         
          {/* Informations sur l'entrepreneur individuel */}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Qui vous êtes"
            name="identiteEntrepreneur"
            value={facture.identiteEntrepreneur}
            onChange={this.handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            
            fullWidth
            label="Raison sociale de l'entreprise principale"
            name="raisonSociale"
            value={facture.raisonSociale}
            onChange={this.handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            
            fullWidth
            label="Adresse de facturation ou du siège social"
            name="adresseFacturation"
            value={facture.adresseFacturation}
            onChange={this.handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            
            fullWidth
            label="Numéro SIREN"
            name="numeroSIREN"
            value={facture.numeroSIREN}
            onChange={this.handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            
            fullWidth
            label="Numéro SIRET"
            name="numeroSIRET"
            value={facture.numeroSIRET}
            onChange={this.handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            
            fullWidth
            label="Numéro RM (Répertoire des Métiers)"
            name="numeroRM"
            value={facture.numeroRM}
            onChange={this.handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            
            fullWidth
            label="Forme juridique"
            name="formeJuridique"
            value={facture.formeJuridique}
            onChange={this.handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            
            fullWidth
            label="Greffe d'immatriculation"
            name="greffeImmatriculation"
            value={facture.greffeImmatriculation}
            onChange={this.handleChange}
          />
          {/* Informations sur le client */}
          <TextField
            variant="outlined"
            margin="normal"
            
            fullWidth
            label="Identité du client"
            name="identiteClient"
            value={facture.identiteClient}
            onChange={this.handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            
            fullWidth
            label="Raison sociale du client"
            name="raisonSocialeClient"
            value={facture.raisonSocialeClient}
            onChange={this.handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            
            fullWidth
            label="Adresse du client"
            name="adresseClient"
            value={facture.adresseClient}
            onChange={this.handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            
            fullWidth
            label="Numéro de TVA du client"
            name="numeroTVA"
            value={facture.numeroTVA}
            onChange={this.handleChange}
          />
          {/* Informations sur la facture */}
          <TextField
            variant="outlined"
            margin="normal"
            
            fullWidth
            label="Date d'émission"
            type="date"
            name="dateEmission"
            value={facture.dateEmission}
            onChange={this.handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            
            fullWidth
            label="Numéro de facture"
            name="numeroFacture"
            value={facture.numeroFacture}
            onChange={this.handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            
            fullWidth
            label="Date de vente ou prestation"
            type="date"
            name="dateVentePrestation"
            value={facture.dateVentePrestation}
            onChange={this.handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            
            fullWidth
            label="Désignation de la prestation"
            name="designationPrestation"
            value={facture.designationPrestation}
            onChange={this.handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            
            fullWidth
            label="Prix unitaire hors TVA"
            name="prixUnitaireHT"
            value={facture.prixUnitaireHT}
            onChange={this.handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            
            fullWidth
            label="Taux de TVA applicable"
            name="tauxTVA"
            value={facture.tauxTVA}
            onChange={this.handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            
            fullWidth
            label="Montant de la TVA"
            name="montantTVA"
            value={facture.montantTVA}
            onChange={this.handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            
            fullWidth
            label="Réductions"
            name="reductions"
            value={facture.reductions}
            onChange={this.handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            
            fullWidth
            label="Montant total à payer"
            name="montantTotal"
            value={facture.montantTotal}
            onChange={this.handleChange}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            Valider
          </Button>
        </form>
      </OnshoPageContainer>
    );
  }

}

export default FactureForm;
