import React, { useEffect, useState } from 'react';
import { Paper, Typography, TextField, Button, Container } from '@mui/material';

function UserManagementPage() {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [error, setError] = useState('');
    const [olduser, setOlduser] = useState(''); 
    const handleUsernameChange = (event) => setUsername(event.target.value) ;
    const handleEmailChange = (event) => setEmail(event.target.value);
    const handleNameChange = (event) => setName(event.target.value);
    const handleDateOfBirthChange = (event) => setDateOfBirth(event.target.value);
    const fetchUser = async (username) => {
        try {
            const response = await fetch(`/b/get-user/${username}`);
            if (response.ok) {
                const data = await response.json();
                setEmail(data.email);
                setName(data.name);
                const date = new Date(data.date_of_birth);
                const formattedDate = date.toISOString().split('T')[0];
                setDateOfBirth(formattedDate); 
            } else {
                setError('Utilisateur non trouvé');
            }
        } catch (error) {
            setError('Erreur lors de la connexion au serveur');
        }
    };
    const updateUser = async () => {
    

        try {
            const response = await fetch('/b/update-user', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    olduser, 
                    username,
                    email,
                    name,
                    dateOfBirth
                }),
            });

            if (response.ok) {
                setOlduser(username);
                localStorage.setItem('userInfo', JSON.stringify({ username }));
                const userInfo = localStorage.getItem('userInfo');
                const user = JSON.parse(userInfo);

            } else {
                setError('Erreur lors de la mise à jour de l’utilisateur');
            }
        } catch (error) {
            setError('Erreur lors de la connexion au serveur');
        }
    };

    
    useEffect(() => {
        const userInfo = localStorage.getItem('userInfo');

        if (userInfo) {
            const user = JSON.parse(userInfo);
            const olduser = user.username
            setUsername(user.username);
            setOlduser(user.username);


            fetchUser(user.username);
        }
    }, []);
 
    return (
        <Container className="user-management-page">
            <Typography variant="h1">GESTIoN DU PROFIL</Typography>
            {error && <div className="error-message">{error}</div>}

            <Paper style={{ padding: 20 }}>
                <TextField
                    label="Nom d'utilisateur"
                    variant="outlined"
                    value={username}
                    onChange={handleUsernameChange}
                    margin="normal"
                    fullWidth
                />
                <TextField
                    label="Email"
                    variant="outlined"
                    value={email}
                    onChange={handleEmailChange}
                    margin="normal"
                    fullWidth
                />
                <TextField
                    label="Nom complet"
                    variant="outlined"
                    value={name}
                    onChange={handleNameChange}
                    margin="normal"
                    fullWidth
                />
                <TextField
                    label="Date de naissance"
                    variant="outlined"
                    value={dateOfBirth}
                    onChange={handleDateOfBirthChange}
                    margin="normal"
                    fullWidth
                    type="date"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
           <Button
                variant="contained"
                color="primary"
                onClick={updateUser}
                style={{ marginTop: 20 }}
            >
                Mettre à jour l'utilisateur
            </Button>
            </Paper>
        </Container>
    );
}

export default UserManagementPage;
