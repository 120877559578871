import React, { useRef } from "react";
import "./css/Spx.css";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { TextField } from "@mui/material";

const Spx=({captationId})=>  {
  console.log(captationId);
  const token = localStorage.getItem('token');
  const iframeRef = useRef(null);
  const [backgroundcolor, setBackgroundcolor] = React.useState("");
  const [name, setName] = React.useState("");
  const [textcolor, setTextColor] = React.useState("");
  const [textfont, setTextFont] = React.useState("");
  const [textsize, setTextSize] = React.useState("");
  const [colorborder, setColorborder] = React.useState("");
  const [bordersize, setBordersize] = React.useState("");
  const [borderradius, setBorderradius] = React.useState("");
  const handlebordersizeChange = (event) => {
    setBordersize(event.target.value);
  }
  const handlebackgroundcolorChange = (event) => {
    setBackgroundcolor(event.target.value);
  }
  const handleborderradiusChange = (event) => {
    setBorderradius(event.target.value);
  }

  const handlebordercolorChange = (event) => {
    setColorborder(event.target.value);
  }
  const handletextfontChange = (event) => {
    setTextFont(event.target.value);
  };
  const handletextsizeChange = (event) => {
    setTextSize(event.target.value);
  };

  const handleChangetextcolor = (event) => {
    setTextColor(event.target.value);
  };
  const handleButtonClick = async () => {
    try{
     
      const iframe = iframeRef.current;
      iframe.contentWindow.updatetest();
    } catch (error) {
      console.error("Error:", error);
    }
  };
   
  
  const handleChangetext = (event) => {
    setName(event.target.value);
  };
  const GetStyle = async () => {
    try {
      console.log("SPX Play:", "GetStyle");
      const response = await fetch(`/b/getstyle/${captationId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        console.log(response);
        throw new Error("Failed to get style");
      }
      console.log("SPX Play:", await response.json());
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const SaveStyle = async () => {
    try {
      console.log("SPX Play:", "SaveStyle");
      console.log(captationId);
      const response = await fetch(`/b/savestyle/${captationId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify({
          textcolor : textcolor,
          textfont: textfont,
          textsize: textsize,
          textbackgroundcolor: backgroundcolor,
          textbordercolor: colorborder,
          bordersize: bordersize,
          borderradius: borderradius,
          textborderwidth : bordersize,
          textborderradius : borderradius,
          f0: name,
          f99: "./themes/default.css",
        }),
      });
      if (!response.ok) {
        console.log(response);
        throw new Error("Failed to save style");
      }
      console.log("SPX Play:", await response.json());
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="spxshow">
      <button
        id="button-animate"/*  */
        style={{ margin: "10px" }}
        onClick={handleButtonClick}
      >
        Animate
      </button>

      <Select
        className="select"
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={textfont}
        label="text-font"
        onChange={handletextfontChange}
      >
        <MenuItem value={"arial"}>Arial Blue</MenuItem>
        <MenuItem value={"roboto"}>Roboto</MenuItem>
        <MenuItem value={"matemasie"}>Matemasie</MenuItem>
      </Select>
      <Select
        className="select"
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={textsize}
        label="text-size"
        onChange={handletextsizeChange}
      >
        <MenuItem value={"small"}>Small</MenuItem>
        <MenuItem value={"medium"}>Medium</MenuItem>
        <MenuItem value={"large"}>Large</MenuItem>
      </Select>
      <div style={{ margin: "10px" }}>
        <label htmlFor="color-picker">Select Color: </label>
        <input
          type="color"
          id="color-picker"
          value={textcolor}
          onChange={handleChangetextcolor}
          style={{ marginLeft: "10px", height: "30px", width: "30px" }}
        />
      </div>
      <div style={{ margin: "10px" }}>
        <label htmlFor="color-picker">Select border Color: </label>
        <input
          type="color"
          id="color-picker"
          value={colorborder}
          onChange={handlebordercolorChange}
          style={{ marginLeft: "10px", height: "30px", width: "30px" }}
        />
      </div>
      <div style={{ margin: "10px" }}>
        <label htmlFor="color-picker">Select background Color: </label>
        <input
          type="color"
          id="color-picker"
          value={backgroundcolor}
          onChange={handlebackgroundcolorChange}
          style={{ marginLeft: "10px", height: "30px", width: "30px" }}
        />
      </div>
      <Select
        className="select"
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={bordersize}
        label="border-size"
        onChange={handlebordersizeChange}
      >
        <MenuItem value={"small"}>Small</MenuItem>
        <MenuItem value={"medium"}>Medium</MenuItem>
        <MenuItem value={"large"}>Large</MenuItem>
      </Select>
      <Select
        className="select"
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={borderradius}
        label="border radius"
        onChange={handleborderradiusChange}
      >
        <MenuItem value={"small"}>small</MenuItem>
        <MenuItem value={"medium"}>medium</MenuItem>
        <MenuItem value={"Large"}>Large</MenuItem>
      </Select>

      

      <TextField
        id="f0-input"
        label="f0 Value"
        variant="outlined"
        value={name}
        onChange={handleChangetext}
        style={{ margin: "10px" }}
      />
      <button id="button-save" style={{ margin: "10px" }} onClick={SaveStyle}>
        Save
      </button>
      <button id="button-get" style={{ margin: "10px" }} onClick={GetStyle}>
        get syle
      </button>
      <div className="spx_iframe">
        <iframe
          ref={iframeRef}
          src="/staticfiles/SPX_1_2_1_win64/SPX_1_2_1_win64/ASSETS/templates/softpix/Template_Pack_1.1/INFO_RIGHT.html"
          style={{
            width: "100%",
            height: "100%",
            border: "none",
          }}
          title="Static Content"
        />
      </div>
    </div>
  );
}

export default Spx;
