import React, { Component } from 'react';
import AVVuMeter from '../AVVuMeter';
import AVFader from '../AVFader';
import AVButton from '../AVButton';


class AVFaderCombo extends Component {
  static defaultProps = {
    audio_destination:null,
    audio_source:null,
    onChange:null,
    label:"",
    muted:false,
    onshokitctrl_url:null,
  }

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount(){
    this.setState({
        active:false
    });
  }

  get_display_text(){
    if(this.props.component == "ClearButton")
      return this.props.input;
    else
      return this.props.info;
  }

  async api_call(path, data){
    const query = `${this.props.onshokitctrl_url}${path}`;
    console.log(query);
    await fetch(query,{
      method: "POST",
      headers: {"Content-Type": "application/json"},
     body: JSON.stringify(data), // body data type must match "Content-Type" header
    });
  }

  render() {
    const c = this.props;
    const class_name = "av faderbtn "  + (this.props.muted?"active":"");
    return (
    <div>
      <div >
        <AVFader label={this.props.label}      
          onChange={(v)=>this.api_call(`/real/audio_destinations/${this.props.audio_destination}`,{[this.props.audio_source]:{value:v}})}          
        />
      </div>
      {/* <AVVuMeter 
          left={this.props.left+60*16/9} top={this.props.top+0}
          F1={this.props.input_data.meterF1}
          F2={this.props.input_data.meterF2}
      /> */}
      <button className={class_name}
              onClick={(v)=>this.api_call(`/real/audio_destinations/${this.props.audio_destination}`,{[this.props.audio_source]:{muted:!this.props.muted}})}>
                Mute
      </button>
    </div>
    );
  }
}

export default AVFaderCombo; 