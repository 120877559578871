import React from 'react';
import {
  Typography,
  TextField,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Paper,
} from "@mui/material";
import ODayTimeField from './ui/ODayTimeField';

const token = localStorage.getItem('token');

class Speaker extends React.Component {
  constructor(props) {
    super(props);
    // Initialise le state avec les valeurs provenant des props
    this.state = {
      speaker: {
        fonction_invite: this.props.speaker.fonction_invite || '',
        observation: this.props.speaker.observation || '',
        selectedGuest: this.props.speaker.guestId ||'',
      },
      guests: [],
      loading: false, // Ajout de l'état pour indiquer l'état de chargement
      error: null
    };
  }
  async componentDidMount() {
    this.setState({ loading: true });
    await this.fetchGuests();
    this.setState({ loading: false }); 
  }

  async fetchGuests() {
    try {
      const response = await fetch("/b/get-guests", {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      if (!response.ok)
        throw new Error("Erreur lors de la récupération des invités");
      const data = await response.json();
      this.setState({ guests: data });
    } catch (err) {
      this.setState({ error: err.message });
    }
  };
  
  async sync_data() {
    try {


      const response = await fetch(`/b/update-speaker/${this.props.captation}/${this.props.sequence._id}/${this.props.chapter}/${this.props.speaker._id}`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
  
        body: JSON.stringify({
          ...this.state.speaker,
          guestId: this.state.speaker.selectedGuest // Ajoutez l'identifiant de l'invité sélectionné
        }),
      });
      if (!response.ok) {
        throw new Error("Erreur lors de la mise à jour de l'intervention");
      }
    } catch (err) {
      this.setState({ error: err.message });
    }
  }


  async deleteSpeaker(chapterId,speakerId) {
    try {
   
      const response = await fetch(`/b/delete-speaker/${chapterId}/${speakerId}`, {
        method: "DELETE",
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Erreur lors de la suppression de l'intervention");
      }
      this.props.onSpeakerDeleted(speakerId);
    }catch (err) {
      this.setState({ error: err.message });
    }
  }

  async on_speaker_attribute_change(event) {
    const property = event.target.getAttribute('name');
    const updated_value = {
      ...this.state.speaker,
    };
    updated_value[property] = event.target.value;
    this.setState({
      speaker: updated_value
    },
      this.sync_data);
  }
  handleGuestSelectionChange = (selectedGuest) => {
    this.setState(prevState => ({
      speaker: {
        ...prevState.speaker,
        selectedGuest: selectedGuest
      }
    }), () => {
      this.sync_data();
    });
  }
  
  render() {
    const { speaker, guests } = this.state;

    return this.props.editable ? (
      <form style={{ marginTop: "1em" }}>
        <Typography variant="h5">intervention {this.props.index + 1}</Typography>
        <TextField
          label="Fonction de l'invite"
          name="fonction_invite"
          value={this.state.speaker.fonction_invite}
          onChange={(e)=>this.on_speaker_attribute_change(e)}
          fullWidth
          margin="normal"
        />
     
        <TextField
          label="Observation"
          name="observation"
          value={this.state.speaker.observation || ""}
          onChange={(e)=>this.on_speaker_attribute_change(e)}
          fullWidth
          margin="normal"
        />
        <InputLabel id="demo-multiple-guests-label">Invités</InputLabel>
        <Select
          labelId="demo-single-guest-label"
          id="demo-single-guest"
          value={speaker.selectedGuest || ''}
          onChange={(event) => {
            this.handleGuestSelectionChange(event.target.value);
          }}
          fullWidth
        >
          {guests && guests.map((guest) => (
            <MenuItem key={guest._id} value={guest._id}>
              <div>
                {guest.nom}
                {guest.photo && <img src={`data:image/jpeg;base64,${guest.photo}`} alt={guest.nom} style={{ width: 50, height: 50 }} />}
              </div>
            </MenuItem>
          ))}
        </Select>
        <Button 
          variant="contained"
          color="secondary"
          onClick={() => this.deleteSpeaker(this.props.chapter,this.props.speaker._id)}
        >
          Supprimer l'intervention
        </Button>
      </form>
    ) : (
      <Paper elevation={2} style={{ padding: "20px", margin: "10px 0" }}>
        <Typography variant="body1">Fonction de l'invite: {this.state.chapter.name}</Typography>
        <Typography variant="body1">Observation: {this.state.chapter.duree_chapitre}</Typography>
      </Paper>
    );
  }
}

export default Speaker;
