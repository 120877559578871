import { createTheme } from '@mui/material/styles';
import "./App.scss";

const onshoColors = {
  white: '#ffffff',
  orange: '#ff661a',
  black: '#000000',
  grey: '#f0f0f5',
  blue: '#1a8cff',
};

const theme = createTheme({
  palette: {
    primary: {
      main: onshoColors.orange,
    },
  },
  typography: {
    fontFamily: 'Switzer, sans-serif',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: 'white',
          borderRadius: '0px',
        },
        containedPrimary: {
          backgroundColor: onshoColors.orange,
          '&:hover': {
            backgroundColor: '#e55a19',
          },
        },
        containedSecondary: {
          backgroundColor: 'transparent',
          color: onshoColors.black,
          border: `1px solid ${onshoColors.black}`,
          '&:hover': {
            backgroundColor: '#0052cc',
          },
        }, 
      }, 
    }, 
    MuiCssBaseline: {
      styleOverrides: {
        p: {
          fontFamily: 'Switzer, sans-serif',
          fontSize: '30px',
          lineHeight: 'normal',
        },
        h1: {
          fontFamily: 'Archiv-Onsho !important',
          fontSize: '50px !important',
          lineHeight: 'normal',
          color: onshoColors.orange,
        },
        
      },
    }, 
  }, 
});

export { theme };
