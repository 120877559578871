import React, { useState, useEffect } from 'react';
import { Button, Container, Typography } from '@mui/material';

import OnshoPageContainer from './OnshoPageContainer';

function VerificationPage() {
  const [message, setMessage] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    // Récupérer les informations de l'utilisateur depuis le stockage local
    const userInfo = localStorage.getItem('userInfo');
    if (userInfo) {
      const user = JSON.parse(userInfo);
      setUsername(user.username);
      setEmail(user.email);
    }
  }, []);

  const handleVerification = async () => {
  
    try {
      const response = await fetch('/b/resend-verification', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username }),
      });
  
      const data = await response.json();
      setMessage(data.msg);
    } catch (error) {
      setMessage('Erreur lors de l’envoi de l’e-mail de vérification.');
    }
  };
  
  return (
    <OnshoPageContainer component="main" maxWidth="xs">
      <Typography component="h1" variant="h5">
        Page de Vérification
      </Typography>
      <Typography>
        Votre compte n'est pas valide, {username}.
      </Typography>
      <Button variant="contained" color="primary" onClick={handleVerification}>
        Renvoyer un mail de vérification à l'adresse : {email}
      </Button>
      {message && <Typography>{message}</Typography>}
    </OnshoPageContainer>
  );
}

export default VerificationPage;
