import React, { useState } from 'react';
import { useParams } from 'react-router-dom';


import OnshoPageContainer from './OnshoPageContainer';

function ResetPassword() {
  const [newPassword, setNewPassword] = useState('');
  const { token } = useParams(); // Récupère le jeton de l'URL

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Envoie la requête POST à votre API Flask
    const response = await fetch(`/b/reset-password-validation/${token}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ password: newPassword })
    });

    // Gérer la réponse...
  };

  return (
    <div>
      <h1>Réinitialisation du Mot de Passe</h1>
      <form onSubmit={handleSubmit}>
        <input 
          type="password" 
          value={newPassword} 
          onChange={(e) => setNewPassword(e.target.value)} 
          placeholder="Nouveau mot de passe"
        />
        <button type="submit">Réinitialiser</button>
      </form>
    </div>
  );
}

export default ResetPassword;
