import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from '../theme'; // Assurez-vous que le chemin d'accès est correct
import WebFont from 'webfontloader';

import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import MailIcon from '@mui/icons-material/Mail';
import { Link } from 'react-router-dom';

import dayjs from 'dayjs';
import SequenceList from '../components/SequenceList';

import Sequence from '../components/Sequence';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import ODayTimeField from './ui/ODayTimeField';


import {
    Typography,
    Grid,
    Tab,
    Box,
    Tabs,
    Alert,
    MenuItem,
    InputLabel,
    Select,
    Container,
    Paper,
    TextField,
    Button,
    Switch,
    FormControlLabel,
    Chip,
    FormControl
  } from "@mui/material";

const token = localStorage.getItem('token');

class Captation extends React.Component {  
  state = {
    captation : this.props.captation,
    error : null,
    info : null ,
    sequences: [], 
    interventions: [],
    typesCaptations: ['Conference', 'Table ronde', 'Assemblee','Masterclass','Formation','Concert','E-sport','Autre'], // Liste des types de captation
    typecaptationSelected:null,
    record: null,
  };

  async componentDidMount(){
    // const recording = this.props.captationId ? await this.fetch_captation() : { ...DEFAULT_CAPTATION };
    // const recording = await this.fetch_captation();
    this.setState({ 
      captation : this.props.captation, 
    });
    if (this.props.captation.typecaptation && this.state.typesCaptations.includes(this.props.captation.typecaptation)) {
      this.setState({typecaptationSelected: this.props.captation.typecaptation})
    } else {
      this.setState({typecaptationSelected: "Autre"})
    }
  
  }
  formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); 
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`; 
  }
  
  formatTime(time_in_minutes,name) {
    const hours = time_in_minutes / 60;
    const minutes = time_in_minutes % 60;
    return hours.toString().padStart(2,"0") + ":" + minutes.toString().padStart(2,"0");
}

  async on_recording_attribute_change_time(value,name){
      const updated_value = {
        ...this.state.captation,
      };
      updated_value[name] = value.hour() * 60 + value.minute();
      this.setState(
        { captation: updated_value },
        this.sync_data
      );    
  }

  async sync_data(){
  
    try {
      const response = await fetch(`/b/update-recording/${this.props.captation._id}`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, 
        },
        body: JSON.stringify(this.state.captation),
      });
      if (!response.ok)
        this.state.error = "Erreur lors de la mise à jour de la captation";
    } catch (err) {
      this.state.error = err.message;
    }
  }
  async typecaptationSelectChange(event){
    const property = event.target.name;
    let value = event.target.value;
    if (value === "Autre") {
      this.setState({typecaptationSelected: value})
      const updated_value = {
        ...this.state.captation,
      };
    
      updated_value[property] = value;
    
      this.setState({
        captation: updated_value
      }, () => {
        this.sync_data();
      });
    }
    else{
      this.setState({typecaptationSelected: value})
      const updated_value = {
        ...this.state.captation,
      };
    
      updated_value[property] = value;
    
      this.setState({
        captation: updated_value
      }, () => {
        this.sync_data();
      });
    }
  }
  async on_recording_attribute_change(event){
    const property = event.target.name;
 
    let value = event.target.value;
    if (property === 'datecaptation') {
      const date = new Date(value);
      value = date.toUTCString();
    }
    const updated_value = {
      ...this.state.captation,
    };
  
    updated_value[property] = value;
  
    this.setState({
      captation: updated_value
    }, () => {
      this.sync_data();
    });
  }
  render() {
      return  <div>
      {this.state.error && <Alert severity="error">{this.state.error}</Alert>}
      {this.state.info && <Alert severity="info">{this.state.info}</Alert>}
      {this.state.captation && 
        <div>
          <TextField
            label="Nom de la captation"
            value={this.state.captation.nomcaptation}
            name="nomcaptation"
            onChange={(e)=>this.on_recording_attribute_change(e)}
            margin="normal"
            fullWidth
          />

{this.state.typecaptationSelected !== "Autre" &&

<Select
  label="Type de captation"
  value={this.state.captation.typecaptation || ''}
  name="typecaptation"
  onChange={(e) => this.typecaptationSelectChange(e)}
  margin="normal"
  fullWidth
  displayEmpty
>
 
  <MenuItem value="" disabled>
    Choisissez votre type de captation
  </MenuItem>
  {this.state.typesCaptations.map((type, index) => (
    <MenuItem key={index} value={type}>
      {type}
    </MenuItem>
  ))}
 
</Select>
}
{ this.state.typecaptationSelected === "Autre" &&
<div>
<Select
  label="Type de captation"
  value={this.state.typecaptationSelected || ''}
  name="typecaptation"
  onChange={(e) => this.typecaptationSelectChange(e)}
  margin="normal"
  fullWidth
>
 
  <MenuItem value="" disabled>
    Choisissez votre type de captation
  </MenuItem>
  {this.state.typesCaptations.map((type, index) => (
    <MenuItem key={index} value={type}>
      {type}
    </MenuItem>
  ))}
 
</Select>
    <TextField
    label = "type de votre captation"
    value={this.state.captation.typecaptation}
    onChange={(e) => this.on_recording_attribute_change(e)}
    name="typecaptation"
    margin="normal"
    fullWidth
    displayEmpty
    />
  </div>
  }



    
        <TextField
    label="Date de la captation"
    type="date"
    value={this.formatDate(this.state.captation.datecaptation)}
    name="datecaptation"
    onChange={(e) => e.target.value !== "" && this.on_recording_attribute_change(e)}
    margin="normal"
    fullWidth
    InputLabelProps={{
        shrink: true,
    }}
/>

          <TextField
            label="Lieu de la captation"
            value={this.state.captation.locationcaptation}
            name="locationcaptation"
            onChange={(e)=>this.on_recording_attribute_change(e)}
            margin="normal"
            fullWidth
          />
          <TextField
            label="durée de la captation en minutes"
            value={this.state.captation.duration}
            name="duration"
            onChange={(e)=>this.on_recording_attribute_change(e)}
            margin="normal"
            fullWidth
          />
           <ODayTimeField
            label="Heure Live"
            value={this.state.captation.heure_live}
            name="heure_live"
            onChange={(value,name)=>this.on_recording_attribute_change_time(value,name)}
            margin="normal"
            fullWidth
          />

            <ODayTimeField
            label="Ouverture portes personnel"
            value={this.state.captation.ouverture_portes_personnel}
            name="ouverture_portes_personnel"
            onChange={(value,name)=>this.on_recording_attribute_change_time(value,name)}
            margin="normal"
            fullWidth
          /> 
          
           <ODayTimeField
            label="Ouverture portes public"
            value={this.state.captation.ouverture_portes_public}
            name="ouverture_portes_public"
            onChange={(value,name)=>this.on_recording_attribute_change_time(value,name)}
            fullWidth
          /> 

            <TextField
            label="instruction acces digicode"
            value={this.state.captation.instruction_acces_digicode}
            name="instruction_acces_digicode"
            onChange={(e)=>this.on_recording_attribute_change(e)}          
            margin="normal"
            fullWidth
            />
            <TextField
            label="instruction acces horaires ouverture"
            value={this.state.captation.instruction_acces_horaires_ouverture}
            name="instruction_acces_horaires_ouverture"
            onChange={(e)=>this.on_recording_attribute_change(e)}          
            margin="normal"
            fullWidth
            />
            <TextField
            label="autres informations utiles"
            value={this.state.captation.autres_informations_utiles}
            name="autres_informations_utiles"
            onChange={(e)=>this.on_recording_attribute_change(e)}                  
            margin="normal"
            multiline
            rows={4}
            fullWidth
            />
          <TextField
          select
          label="Contact événement client"
          value={this.state.captation.contact_evenement_client || ''}
          name="contact_evenement_client"
          onChange={(e) => this.setState({ captation: {...this.state.captation, contact_evenement_client: e.target.value } }, this.sync_data)}
          margin="normal"
          fullWidth
          >
            {this.props.users.map((user, index) => (
              <MenuItem key={index} value={user._id}>
                {user.name} - {user.email}
              </MenuItem>
            ))}
          </TextField>
          <TextField
          select
          label="contact responsable internet"
          value={this.state.captation.contact_responsable_internet || ''}
          onChange={(e) => this.setState({ captation: {...this.state.captation, contact_responsable_internet: e.target.value } },this.sync_data)}
          margin="normal"
          fullWidth
          >
            {this.props.users.map((user, index) => (
              <MenuItem key={index} value={user._id}>
                {user.name} - {user.email}
              </MenuItem>
            ))}
          </TextField>
          <input 
          type="file"
          name="ConducteurFile"
          accept='.pdf'
          />
          <FormControlLabel
          control={
          <Switch
          checked={this.state.captation.record} 
          onChange={(e) => this.setState({ captation: {...this.state.captation, record: e.target.checked } }, this.sync_data)}
          />
          }
          label="Je souhaite obtenir les enregistrements"
          />
          </div>}
          MEDIAS
          INVITÉS
          <Paper elevation={4} style={{ padding: "2em" }}>
            <h1>Séquences</h1>
            <SequenceList sequences={this.props.sequences} interventions={this.state.interventions} captation={this.state.captation} editable={true}/>
          </Paper>
        </div>;
  }
}
  

export default Captation;