// App.js
import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, Typography, Button } from '@mui/material';
import { theme } from './theme'; // Assurez-vous que le chemin d'accès est correct
import WebFont from 'webfontloader';
import OSlider from './components/ui/OSlider';
import OVerticalSlider from './components/ui/OVerticalSlider';

function Themetemplate() {

  
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div style={{ margin: '20px' }}>
        <Typography variant="h1">Bienvenue chez Onsho</Typography>
        <Typography paragraph>
          Ceci est un exemple de texte utilisant le thème personnalisé.
        </Typography>
        <Button variant="contained" color="primary">
          Bouton Principal
        </Button>
        <Button variant="contained" color="secondary" style={{ marginLeft: '10px' }}>
          Bouton Secondaire
        </Button>
        
        <OSlider vertical={false}></OSlider>
        <OSlider vertical={true}></OSlider>
        <OVerticalSlider ></OVerticalSlider>
      </div>
    </ThemeProvider>
  );
}

export default Themetemplate;
