import React, { useEffect, useState } from 'react';
import { Button, Container, Typography, TextField, Grid, Card } from '@mui/material';

function AdminPage() {
  const [password, setPassword] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [captations, setCaptations] = useState([]);
  const token = localStorage.getItem('token');
  const [chapter, setChapter] = useState([]);
  const [factures, setFactures] = useState([]);
  const handleLogin = async() => {
    const response = await fetch('/b/adminlogin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded', 
        'Authorization': `Bearer ${token}`,
      },
      body: new URLSearchParams({
        'password': password,
      }),
    });
    const data = await response.json();
    if (!response.ok) {
      alert(data.msg || 'Erreur de connexion');
      return;
    }
    else{
      setIsLoggedIn(true);
      localStorage.setItem('token', data.access_token);
      localStorage.setItem('userInfo', JSON.stringify({ username: 'admin' }));
      getcaptations();
      getchapter();
      getfacture();
    }
  }
 
  const getchapter = async () => {
    const response = await fetch('/b/get-chapters', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    const data = await response.json();
    if (!response.ok) {
      alert(data.msg || 'Erreur de connexion');
      return;
    }
    else{
      setChapter(data);
    }
  };
  const getfacture = async () => {
    const response = await fetch('/b/get-facture', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    const data = await response.json();
    if (!response.ok) {
      alert(data.msg || 'Erreur de connexion');
      return;
    }
    else{
      setFactures(data);
    }
  };


  const getcaptations = async () => {
    const response = await fetch('/b/get-captations', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    const data = await response.json();

    if (!response.ok) {
      alert(data.msg || 'Erreur de connexion');
      return;
    } else {

      setCaptations(data); 
    }
  };
  return (
    <Container component="main" maxWidth="xs">
      {isLoggedIn ? (
        <div>
          <Typography variant="h5">ADMIN</Typography>
          <Card>
            <Typography variant="h6">Toutes les captations</Typography>
            {captations.map((captation, index) => (
              <div key={index}>
                <Typography variant="subtitle1">Nom de la captation : {captation.nomcaptation}</Typography>
                <Typography variant="body2">Type de captation : {captation.typecaptation}</Typography>
                <Typography variant="body2">Date de captation : {new Date(captation.datecaptation).toLocaleDateString()}</Typography>
                {/* Ajoutez d'autres détails de la captation ici */}
                <hr /> {/* Ligne de séparation entre chaque captation */}
              </div>
            ))}

          </Card>
          <Card>
            <Typography variant="h6">Tous les chapitres</Typography>
            {chapter.map((chap, index) => (
              <div key={index}>
                <Typography variant="subtitle1">Nom du chapitre : {chap.name}</Typography>
                {/* Ajoutez d'autres détails du chapitre ici */}
                <hr /> {/* Ligne de séparation entre chaque chapitre */}
              </div>
            ))}
          </Card>
            <Card>
              <Typography variant='h6'>Toute les factures</Typography>
              {factures.map((facture, index) => (
                <div key={index}>
                  <Typography variant='subtitle1'>Nom du client : {facture.identiteEntrepreneur}</Typography>
                  <Typography variant='body2'>Montant : {facture.montant}</Typography>
                  <Typography variant='body2'>Date : {new Date(facture.date).toLocaleDateString()}</Typography>
                  <hr />
                </div>
              ))}
            </Card>
        </div>
      ) : (
        <div>
          <TextField
            type="password"
            label="Mot de passe"
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button variant="contained" color="primary" onClick={handleLogin}>
            Connexion
          </Button>
        </div>
      )}
    </Container>
  );
}

export default AdminPage;