import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from '../theme'; // Assurez-vous que le chemin d'accès est correct
import WebFont from 'webfontloader';

import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import MailIcon from '@mui/icons-material/Mail';
import { Link } from 'react-router-dom';

import Speaker from './Intervention';

import {
    Button,
    Switch,
    FormControlLabel,
    Chip,
    FormControl,
  } from "@mui/material";


  const token = localStorage.getItem('token');


class SpeakerList extends React.Component {  
  static defaultProps = {
    captation: null,
    guests:    [],
    sequence: null,
    chapters: null
  }

  state = {
    speakers:  this.props.speakers || [],
  }
  onSpeakerDeleted = (deletedSpeakerId) => { 
    this.setState({
        speakers: this.state.speakers.filter(speaker => speaker._id !== deletedSpeakerId)
    });
  }

  async componentWillMount(){
    await this.fetchSpeaker(this.props.chapter._id);
      }

  async fetchSpeaker(chapterId) {
    this.setState({loading: true});
    try {
      const response = await fetch(`/b/speakers-for-chapter/${chapterId}`,{
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      if (!response.ok)
        throw new Error("Erreur lors de la récupération des séquences");
      const data = await response.json();
      this.setState({speakers: data, loading:false});
    } catch (err) {
      this.setState({error: err.message, loading:false});
    } 
  }
    
  
  async addSpeaker(e) {
    e.preventDefault();

    try{
      const response = await fetch(`/b/add-speaker/${this.props.captation}/${this.props.sequence._id}/${this.props.chapter._id}`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        
      });

      if (!response.ok)
        throw new Error("Erreur lors de l'ajout de l'intervention");
      const data = await response.json();
      
      this.setState({
        speakers : this.state.speakers.concat([data.speaker])
      }) 
    } catch (err) {
      this.state.error = err.message ;
    }
  };


  render() {
      let ret = <div>
        <h1>Intervention</h1>
       {this.state.speakers.length > 0 ? (
         this.state.speakers.map((speaker, index) => (
          
          <Speaker
          key={index} 
          captation={this.props.captation}
          sequence={this.props.sequence}
          chapter={this.props.chapter._id}
          interventions={this.props.interventions}
          index={index}
          speaker={speaker}
          editable={this.props.editable}
          captationId={this.props.captationId}
          onSpeakerDeleted={this.onSpeakerDeleted}
        />    
        )) 
      ) : (
        <p>Pas d'interventions pour cette sequence.</p>
      )} 
      </div>;

      return <div> {ret}
      <Button size="large" variant="contained" color="primary" onClick={ (e) => this.addSpeaker(e)} style={{ marginTop: "2em" }}>
        Ajouter une intervention
      </Button>
      </div>;
  }
}
  

export default SpeakerList;
