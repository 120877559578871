import React from 'react';
import {
  Typography,
  TextField,
  Button,
  Switch,
  FormControlLabel,
  Paper,
} from "@mui/material";
import ODayTimeField from './ui/ODayTimeField';
import SpeakerList from './InterventionList'; // Importez le composant SpeakerList

const token = localStorage.getItem('token');

class Chapter extends React.Component {
  state = {
    chapter: this.props.chapter,
    error: null
  };

  async sync_data() {
    try {
        //<captationId>/<sequenceId>/<chapterId>/<speakerId>'
      const response = await fetch(`/b/update-chapter/${this.props.captation}/${this.props.sequence._id}/${this.props.chapter._id}`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(this.state.chapter),
      });
      if (!response.ok) {
        throw new Error("Erreur lors de la mise à jour du chapitre");
      }
    } catch (err) {
      this.setState({ error: err.message });
    }
  }

  on_attribute_change_time = (value, name) => {
    this.setState(prevState => ({
      chapter: {
        ...prevState.chapter,
        [name]: value.hour() * 60 + value.minute()
      }
    }), this.sync_data);
  };

  async on_recording_attribute_change_time(value,name){
    const updated_value = {
      ...this.state.chapter,
    };
    updated_value[name] = value.hour() * 60 + value.minute();
    this.setState(
      { chapter: updated_value },
      this.sync_data
    );    
  }
  async deleteChapitre(chapterId) { // Méthode pour supprimer une séquence via une requête DELETE et l'id de la séquence
    try {
        const response = await fetch(`/b/delete-chapter/${chapterId}`, {
            method: "DELETE",
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
        if (!response.ok) {
            throw new Error("Erreur lors de la suppression de la séquence");
        }
        this.props.onChapterDeleted(chapterId);
    } catch (err) {
        console.error(err.message);
    }
}
  async on_chapter_attribute_change(event) {
    const property = event.target.getAttribute('name');
    const updated_value = {
      ...this.state.chapter,
    };
    updated_value[property] = event.target.value;
    this.setState({
      chapter: updated_value
    },
      this.sync_data);
  }

  render() {
    return this.props.editable ? (
      <form style={{ marginTop: "1em" }}>
        <Typography variant="h5">Chapitre {this.props.index + 1}</Typography>
        <TextField
          label="Nom du chapitre"
          name="name"
          value={this.state.chapter.name}
          onChange={(e)=>this.on_chapter_attribute_change(e)}
          fullWidth
          margin="normal"
        />
        <ODayTimeField
          label="Heure de début"
          name="heure_debut"
          value={this.state.chapter.heure_debut}
          onChange={(value,name)=>this.on_recording_attribute_change_time(value,name)}
          fullWidth
        />
        <TextField
          label="Durée du chapitre"
          name="duree_chapitre"
          value={this.state.chapter.duree_chapitre}
          onChange={(e)=>this.on_chapter_attribute_change(e)}
          fullWidth
          margin="normal"
        />
        <Paper key={this.props.index} elevation={4} style={{ padding: "2em", margin: "2em 0" }}>
          {/* Intégrez SpeakerList avec les props nécessaires */}
          <SpeakerList 
            editable={this.props.editable}  
            captation={this.props.sequence.captationId} 
            sequence={this.props.sequence} 
            chapter={this.props.chapter} 
            interventions={this.props.interventions} 
            captationId={this.props.captationId} 
          />
        </Paper>
        <Button // Bouton pour supprimer la séquence
          variant="contained"
          color="secondary"
          onClick={() => this.deleteChapitre(this.props.chapter._id)}
        >
          Supprimer le chapitre
        </Button>
      </form>
    ) : (
      <Paper elevation={2} style={{ padding: "20px", margin: "10px 0" }}>
        <Typography variant="h6">Chapitre: {this.state.chapter.Nom}</Typography>
        <Typography>Heure de début: {this.state.chapter.heure_debut}</Typography>
        <Typography>Durée: {this.state.chapter.duree_chapitre} minutes</Typography>
        <Typography>Enregistrement: {this.state.chapter.enregistrement ? 'Oui' : 'Non'}</Typography>
      </Paper>
    );
  }
}

export default Chapter;
