import React from 'react';
import AVButton from '../components/ui/av/AVButton';
import AVInputCombo from '../components/ui/av/controllers/AVInputCombo';
import AVFaderCombo from '../components/ui/av/controllers/AVFaderCombo';
import AVVuMeter from '../components/ui/av/AVVuMeter';
import AVFader from '../components/ui/av/AVFader';
import { useParams } from 'react-router-dom';

import '../components/ui/av/avstyle.scss';
import AVPiPSourceSelector from '../components/ui/av/controllers/AVPiPSourceSelector';

const INPUT_DUMMY = {
  preview: false,
  active: false,
  meterF1: 0,
  meterF2: 0,
  muted: true,
  dummy: true,
};

const styles = {
};
const token = localStorage.getItem('token');
function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}
class AVCtrlDirector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        error: null,
        onshokitctrl_url:"",
        realstatus:null,
        sync_guests_from_pa:false,
        sync_guests_from_pgm:false,
        sync_pcs_from_pa:false,
        sync_pcs_from_pgm:false,
        active_panel:"lowerthird",
        selecting_pip_source:false,
        selectedPiP:1,
        l3rd1guest:"l3rd 1",
        l3rd1guestphoto:"",
        l3rd2guestphoto:"",
        l3rd2guest:"l3rd 2",
        captationId: this.props.params.captationId,

    
        };

  }

  componentDidMount() {
    
    this.fetchGuests();
    this.check = async () => {
      const query = `${this.state.onshokitctrl_url}/real`;
      const response = await fetch(query);
      if(response.ok){
        const data = await response.json();
        this.setState({
          realstatus:data
        })
        setTimeout(this.check, 500);
    }
    else{
      console.log("error getting "+ query);
    }
  }
  this.check()

  }
  
  async componentWillUnmount(){
    clearTimeout(this.check)
  }
  fetchGuests = async () => {
    try {
       
        const response = await fetch('/staticfiles/data/guests.json', {
          method: 'GET',
          headers: {
              'Authorization': `Bearer ${token}`,
          },
      });
      if (!response.ok) {
          throw new Error('Erreur lors de la récupération des invités');
      }
      
      
      
      const guests = await response.json(); 
      this.setState({ guests });
      console.log('Guests:', guests);
    } catch (error) {
      console.error("Error fetching guests:", error);
      this.setState({ error: "Erreur lors de la récupération des invités" });
    }
  }
  
  
  

  interval = async () => {

    const query = `${this.state.onshokitctrl_url}/real`;
    const response = await fetch(query);
    if (response.ok) {
      const data = await response.json();
      this.setState({
        vmixstatus: data.vmix,
        realstatus: data
      });
      setTimeout(this.interval, 300);
    } else {
      console.log("error getting " + query);
    }
  }

  api_call = async (path, data) => {
    const query = `${this.state.onshokitctrl_url}${path}`;
    await fetch(query, {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(data),
    });
  }

  get_input(selector){
    return this.state.realstatus.vmix.inputs.input.find(i=>i.number === selector) ||
    this.state.realstatus.vmix.inputs.input.find(i=>i.shortTitle === selector) ||
    this.state.realstatus.vmix.inputs.input.find(i=>i.title === selector) ||
    this.state.realstatus.vmix.inputs.input.find(i=>i.key === selector) || 
    {...INPUT_DUMMY};
  }
  
  invokeSPXFunction = async (guestName) => {
    try {
      console.log("invokeSPXFunction", guestName);
        const response = await fetch(`/spx/invoke-directplayout/${guestName}/${this.state.captationId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },

            
        });
        if (!response.ok) {
            throw new Error('Failed to invoke SPX directplayout');
        }
        console.log('SPX Play:', await response.json());
    } catch (error) {
        console.error("Error:", error);
    }
}
stopAnimation = async () => {
  try {
      const response = await fetch('/spx/stop-directplayout', {
          method: 'GET',
          headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
          }
      });
      if (!response.ok) {
          throw new Error('Failed to stop SPX directplayout');
      }
      const result = await response.json();
      console.log('SPX Stop:', result);
  } catch (error) {
      console.error("Error stopping SPX animation:", error);
  }
}
  


  set_pip_source(key){
    console.log("set", key, "to overlay", this.state.selectedOverlay, "of pip", this.state.selectedPiP);
    this.api_call(`/real/pips/${this.state.selectedPiP}/sources/${this.state.selectedOverlay}`,
      {source:key}
    )
    this.setState({
      selecting_pip_source:false
    })
    
  }
  activateL3rd1 (guestName) {

    this.setState({ l3rd1guest: guestName });
    this.setState({ l3rd1guestphoto: this.state.guests.find(guest => guest.nom === guestName).photo })
  }
  activateL3rd2 (guestName) {
    this.setState({ l3rd2guest: guestName });
    this.setState({ l3rd2guestphoto: this.state.guests.find(guest => guest.nom === guestName).photo })
  }
  is_active_pip_source(key){
    if(this.state.selecting_pip_source){
      const input = this.get_input(key);
      for(const source of this.state.realstatus.avctrl.pips[this.state.selectedPiP].sources){
        const sourceinput = this.get_input(source.key);
        if(input.key === sourceinput.key)
          return true;
      }
    }
    return false;
  }

  render() {
    if(this.state.realstatus == null)
      return <div>vmix not ready</div>;
    const cameras = Array.from(new Array(6).keys()).map((i)=>this.get_input(`Cam ${i+1}`));
    const guests = Array.from(new Array(3).keys()).map((i)=>this.get_input(`Guest ${i+1}`));
    // console.log(guests)
    const PCs = Array.from(new Array(3).keys()).map((i)=>this.get_input(`PC ${i+1}`));
    const PiPs = Array.from(new Array(4).keys()).map((i)=>this.get_input(`PiP ${i+1}`));
    const self = this;
    
    const g = 75;
    const minmarg = 8;
    const innersize = g - minmarg *2;
    const hdinnersize = innersize * 1.77777777777;
    const hdmargin = (g*2 - hdinnersize)/2;
    const { guestsbutton } = this.state;

    return <div style={{padding:5,margin:5,backgroundColor:"#333",height:"fit-content"}}>
   
   
     

     
      
<div className="av hwshell maincontainer">

<div id="triptic" className="av hbox">
  <div id="triptic-left" className="av vbox" >
    <div id="cameras" className="av panel vbox">
      <div  id="cameras-cut" className="av hbox">
        {cameras.map((camera,i)=>(
          (camera && 
            <AVButton
              key={"cameracut" + i}
              label={camera.shortTitle || `(Cam ${i+1})`}
              onClick={(e)=>{
                console.log(e)
                e.target.classList.add("active")
                e.target.classList.remove("red")
                this.api_call("/real/destinations/program",{source:camera.key})
              }}
              active={this.state.realstatus.vmix.active === camera.number}
              active_color={"red"}
              dummy={camera.dummy}
          />)
        ))}
      </div>
      <div id="cameras-preview" className="av hbox">
        {cameras.map((camera,i)=>(
          (camera && 
            <AVButton
              key={"cameraprev" + i}
              label={camera.shortTitle || `(Cam ${i+1})`}
              suspend_waiting={this.state.selecting_pip_source}
              suspend_waiting_color={this.is_active_pip_source(camera.key)?"blue":"white"}
              onClick_suspend_waiting={(e)=>this.set_pip_source(camera.key)}
              onClick={(e)=>this.api_call("/real/destinations/preview",{source:camera.key})}
              active={this.state.realstatus.vmix.preview === camera.number}
              active_color={"green"}
              dummy={camera.dummy}
              
              // pip_destinations={this.state.realstatus.avctrl.pips}
              // selection_active={this.state.selecting_pip_source}
              // on_selection_start={(selectedPiP,selectedOverlay)=>{
              //   this.setState({
              //     selecting_pip_source:true,
              //     selectedPiP:selectedPiP,
              //     selectedOverlay:selectedOverlay
              //   })
              // }}
          />)
        ))}
      </div>
    </div>
    <div id="otherinputs" className="av panel vbox">
      <div  id="Cut" className="av hbox" style={{flexDirection:"row-reverse"}}>
        <button className='av largebtn cut'
              onClick={(e)=>this.api_call("/real/destinations/program",{source:this.state.realstatus.vmix.preview})}
        >CUT</button>
        <button className='av largebtn'>Fade</button>
      </div>
      <div  id="guests" className="av hbox">
        {guests.map((guest,i)=>(
          <AVInputCombo
            input_data = {guest}
            label={`Guest ${i+1}`}
            suspend_waiting={this.state.selecting_pip_source}
            suspend_waiting_color={this.is_active_pip_source(guest.key)?"blue":"white"}
            onClick_suspend_waiting={(e)=>this.set_pip_source(guest.key)}
            onshokitctrl_url={this.state.onshokitctrl_url}
            active={this.state.realstatus.vmix.active === guest.number || this.state.realstatus.vmix.preview === guest.number  }
            active_color={this.state.realstatus.vmix.active===guest.number?"red":"green"}
          />
        ))}
      </div>
      <div  id="pcs" className="av hbox">
          {PCs.map((pc,i)=>(
            <AVInputCombo
              input_data = {pc}
              label={`PC ${i+1}`}
              suspend_waiting={this.state.selecting_pip_source}
              suspend_waiting_color={this.is_active_pip_source(pc.key)?"blue":"white"}
              onClick_suspend_waiting={(e)=>this.set_pip_source(pc.key)}
              onshokitctrl_url={this.state.onshokitctrl_url}
              input={`PC ${i+1}`}
              active={this.state.realstatus.vmix.active === pc.number || this.state.realstatus.vmix.preview === pc.number  }
              active_color={this.state.realstatus.vmix.active===pc.number?"red":"green"}
            />
          ))}
      </div>
      <div>
      <button className='av largebtn'  onClick={() => this.invokeSPXFunction(this.state.l3rd1guest)}
      style={{ backgroundImage: `url(data:image/jpeg;base64,${this.state.l3rd1guestphoto})`,
      backgroundSize: 'cover', 
      backgroundPosition: 'center', 
      
      }}  ><p style={{backgroundColor:"white",width:"50%", margin:"auto"}}>{this.state.l3rd1guest}</p></button>

      <button className='av largebtn'  onClick={() => this.invokeSPXFunction(this.state.l3rd2guest)}  
      style={{ backgroundImage: `url(data:image/jpeg;base64,${this.state.l3rd2guestphoto})`,
      backgroundSize: 'cover', // Assure que l'image couvre tout le bouton
      backgroundPosition: 'center', // Centre l'image dans le bouton
      }}  ><p style={{backgroundColor:"white",width:"50%", margin:"auto"}}>{this.state.l3rd2guest}</p></button>

      </div>
    </div>
  </div>
  <div id="triptic-left2" className="av vbox panel" >
          {PiPs.map((pip,i)=>(
            <AVInputCombo
              input_data = {pip}
              label={`PiP ${i+1}`}
              onClick={(e)=>{this.setState({selectedPiP:i+1})}}
              onshokitctrl_url={this.state.onshokitctrl_url}
              input={`PiP ${i+1}`}
              active={this.state.realstatus.vmix.active === pip.number || this.state.realstatus.vmix.preview === pip.number  }
              active_color={this.state.realstatus.vmix.active===pip.number?"red":"green"}
            />
          ))}
  </div>
  <div id="triptic-middle" className="av vbox panel" >
  <div id="tabs" className="av hbox" style={{width:75*7,height:75*5.5}}>  
    <div className="av vbox">  
    <div className="av hbox">  
    
    <button 
      className={"av tabbtn " + (this.state.active_panel === "mixer"?"active":"")} 
      onClick={()=>this.setState({active_panel:"mixer"})}> mixer</button>
    <button 
      className={"av tabbtn " + (this.state.active_panel === "pip"?"active":"")} 
      onClick={()=>this.setState({active_panel:"pip"})}> PiP</button>
      <button 
      className={"av tabbtn " + (this.state.active_panel === "lowerthird"?"active":"")} 
      onClick={()=>this.setState({active_panel:"lowerthird"})}> l3rd</button>
    </div>
    <div className="av panel" style={{width: 485, height:355, marginTop:0}}>
      {this.state.active_panel === "mixer" && (
      <div id="mixer" className="av hbox" style={{width:75*7,height:75*5.5}}>  
        <AVFaderCombo label="Micros" 
          audio_destination="master"
          audio_source="micros"
          muted={this.state.realstatus.avctrl.audio_destinations.master.micros.muted}
          onshokitctrl_url={this.state.onshokitctrl_url} />
        <AVFaderCombo label="Média" 
          audio_destination="master"
          audio_source="medias"
          muted={this.state.realstatus.avctrl.audio_destinations.master.medias.muted}
          onshokitctrl_url={this.state.onshokitctrl_url} />
        <AVFaderCombo label="Guest 1" 
          audio_destination="master"
          audio_source="guest1"
          muted={this.state.realstatus.avctrl.audio_destinations.master.guest1.muted}
          onshokitctrl_url={this.state.onshokitctrl_url} />
        <AVFaderCombo label="Guest 2" 
          audio_destination="master"
          audio_source="guest2"
          muted={this.state.realstatus.avctrl.audio_destinations.master.guest2.muted}
          onshokitctrl_url={this.state.onshokitctrl_url} />
        <AVFaderCombo label="Guest 3" 
          audio_destination="master"
          audio_source="guest3"
          muted={this.state.realstatus.avctrl.audio_destinations.master.guest3.muted}
          onshokitctrl_url={this.state.onshokitctrl_url} />
        <AVFaderCombo label="PC 1" 
          onChange={(v)=>this.api_call("/real/audio_destinations/master",{pc1:{value:v}})}
          audio_destination="master"
          audio_source="pc1"
          muted={this.state.realstatus.avctrl.audio_destinations.master.pc1.muted}
          onshokitctrl_url={this.state.onshokitctrl_url}
        />
        <AVFaderCombo label="PC 2" 
          onChange={(v)=>this.api_call("/real/audio_destinations/master",{pc2:{value:v}})}
          audio_destination="master"
          audio_source="pc2"
          muted={this.state.realstatus.avctrl.audio_destinations.master.pc2.muted}
          onshokitctrl_url={this.state.onshokitctrl_url}
        />
        <AVFaderCombo label="PC 3" 
          onChange={(v)=>this.api_call("/real/audio_destinations/master",{pc3:{value:v}})}
          audio_destination="master"
          audio_source="pc3"
          muted={this.state.realstatus.avctrl.audio_destinations.master.pc3.muted}
          onshokitctrl_url={this.state.onshokitctrl_url}
        />

      </div>
        )}
        {this.state.active_panel == "pip" &&(
          <div>
            <AVPiPSourceSelector
              pip_destinations={this.state.realstatus.avctrl.pips}
              selection_active={this.state.selecting_pip_source}
              selectedPiP={this.state.selectedPiP}
              on_selection_start={(selectedPiP,selectedOverlay)=>{
                this.setState({
                  selecting_pip_source:true,
                  selectedPiP:selectedPiP,
                  selectedOverlay:selectedOverlay
                })
              }}
            ></AVPiPSourceSelector>
              <div>
                <AVVuMeter
                    F1={this.state.realstatus.vmix.audio.master.meterF1}
                    F2={this.state.realstatus.vmix.audio.master.meterF2} />
                <img  alt="snapshot" style={{height:65}}
                  // src={`${this.state.onshokitctrl_url}/real/inputs/input/1/snapshot`}
                ></img> 
              </div>
            </div>
        )}
            {this.state.active_panel === "lowerthird" && (
  <div className="guest-panel" style={{ overflowY: 'auto', height: '300px', width: '100%', padding: '10px' }}>
    {this.state.guests.map((guest, index) => (
      <div className="guest-row" key={index}>
        <button className="av guestbtn left" onClick={() => this.invokeSPXFunction(guest.nom)} >IN</button>
        <div className="guest-name av"> 
          <div> {guest.photo && <img src={`data:image/jpeg;base64,${guest.photo}`} alt={guest.nom} style={{ width: 70 , height: 70 }} />}</div>
          <div> {guest.nom}<br></br>{guest.prenom}</div>              
       </div>
       <button className="av guestbtn left" onClick={() => this.activateL3rd1(guest.nom)}  style={{
                                    backgroundColor: guest.nom === this.state.l3rd1guest ? "green" : "rgb(83,87,93)"
                                }}>1</button>
       <button className="av guestbtn right" onClick={() => this.activateL3rd2(guest.nom)}style={{
                                    backgroundColor: guest.nom === this.state.l3rd2guest ? "green" : "rgb(83,87,93)"
                                }}>2</button>
      </div>
    ))}
  </div>
)}

  </div>
    <div>
      {/* <button style={{backgroundColor:this.state.sync_guests_from_pa?"red":"gray", margin:"2px 50px"}}>sync with PA</button>
      <button style={{backgroundColor:this.state.sync_pcs_from_pa?"red":"gray", margin:"2px 50px"}}>sync with PA</button> */}
    </div>
    </div>
  </div>
    
  </div>
  <div id="triptic-middle" className="av vbox panel" >
    <div id="tabs" style={{width:75*8.5,height:75*5.5}}>   
      <h1 style={{color:"#444"}}>Conducteur</h1>
    </div>
  </div>
</div>
  <button style={{background:"#333",width:hdinnersize+g*2,margin:`${minmarg}px ${hdmargin}px ${minmarg}px ${hdmargin}px`,height:innersize}}></button>
  <button style={{background:"#333",width:hdinnersize+g*2,margin:`${minmarg}px ${hdmargin}px 5px ${hdmargin}px`,height:innersize}}></button>

  <button className='av squarebtn'></button>
  <button className='av squarebtn'></button>
  <button className='av squarebtn'></button>
  <button className='av squarebtn'></button>
  <button className='av squarebtn'></button>
  <button className='av squarebtn'></button>  
  <button className='av squarebtn' onClick={this.stopAnimation}>OUT</button>

  </div>

    </div>;
  }
}

export default withParams(AVCtrlDirector);