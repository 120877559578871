import React from 'react';
import '../components/ui/av/avstyle.scss';


class AVCtrlLayout extends React.Component {
  state = {
  };

  render() {
    const g = 75;
    const minmarg = 8;
    const innersize = g - minmarg *2;
    const hdinnersize = innersize * 1.77777777777;
    const hdmargin = (g*2 - hdinnersize)/2;
    return <div  style={{
      margin:0,
    }}>
      <div style={{height:540, width:960,background:"green"}}></div>
      <div style={{position:"absolute",left:960,top:0,height:540, width:960,background:"red"}}></div>
      <div className="av hwshell maincontainer">

      <div id="triptic" className="av hbox">
        <div id="triptic-left" className="av vbox" >
          <div id="cameras" className="av panel vbox">
            <div  id="cameras-cut" className="av hbox">
              <button className='av squarebtn active red'>Cam 1</button>
              <button className='av squarebtn'>Cam 2</button>
              <button className='av squarebtn'>Cam 3</button>
              <button className='av squarebtn'>Cam 4</button>
              <button className='av squarebtn'>Cam 5</button>
              <button className='av squarebtn'>Cam 6</button>
            </div>
            <div id="cameras-preview" className="av hbox">
              <button className='av squarebtn'>Cam 1</button>
              <button className='av squarebtn active green'>Cam 2</button>
              <button className='av squarebtn'>Cam 3</button>
              <button className='av squarebtn'>Cam 4</button>
              <button className='av squarebtn'>Cam 5</button>
              <button className='av squarebtn'>Cam 6</button>
            </div>
          </div>
          <div id="otherinputs" className="av panel vbox">
            <div  id="Cut" className="av hbox" style={{flexDirection:"row-reverse"}}>
              <button className='av largebtn'>CUT</button>
              <button className='av largebtn'>Fade</button>
            </div>
            <div  id="pcs" className="av hbox">
              <button className='av largebtn'>Guest 1</button>
              <button className='av largebtn'>Guest 2</button>
              <button className='av largebtn'>Guest 3</button>
            </div>
            <div  id="guests" className="av hbox">
              <button className='av largebtn'>Pc 1</button>
              <button className='av largebtn'>Pc 2</button>
              <button className='av largebtn'>Pc 3</button>
            </div>
          </div>
        </div>
        <div id="triptic-left2" className="av vbox panel" >
          <button className='av largebtn'>PiP 1</button>
          <button className='av largebtn'>PiP 2</button>
          <button className='av largebtn'>PiP 3</button>
          <button className='av largebtn'>PiP 4</button>
        </div>
        <div id="triptic-middle" className="av vbox panel" >
          <div id="tabs" className="av hbox" style={{width:75*7,height:75*5.5}}>   


          <div className="avfader-container">
                <span className="avfader-label">Master</span>
                <input
                    type="range"
                    orientation="vertical"
                    min={0}
                    max={100}
                    value={33}
                    className="avfader-input vertical"
                />
                <span className="avfader-value">{0}</span>
            </div>
            
          <div className="avfader-container">
                <span className="avfader-label">Master</span>
                <input
                    type="range"
                    orientation="vertical"
                    min={0}
                    max={100}
                    value={33}
                    className="avfader-input vertical"
                />
                <span className="avfader-value">{0}</span>
            </div>
            
          <div className="avfader-container">
                <span className="avfader-label">Master</span>
                <input
                    type="range"
                    orientation="vertical"
                    min={0}
                    max={100}
                    value={33}
                    className="avfader-input vertical"
                />
                <span className="avfader-value">{0}</span>
            </div>
            
          <div className="avfader-container">
                <span className="avfader-label">Master</span>
                <input
                    type="range"
                    orientation="vertical"
                    min={0}
                    max={100}
                    value={33}
                    className="avfader-input vertical"
                />
                <span className="avfader-value">{0}</span>
            </div>
            
          <div className="avfader-container">
                <span className="avfader-label">Master</span>
                <input
                    type="range"
                    orientation="vertical"
                    min={0}
                    max={100}
                    value={33}
                    className="avfader-input vertical"
                />
                <span className="avfader-value">{0}</span>
            </div>
            
          <div className="avfader-container">
                <span className="avfader-label">Master</span>
                <input
                    type="range"
                    orientation="vertical"
                    min={0}
                    max={100}
                    value={33}
                    className="avfader-input vertical"
                />
                <span className="avfader-value">{0}</span>
            </div>



        </div>
          
        </div>
        <div id="triptic-middle" className="av vbox panel" >
          <div id="tabs" style={{width:75*8.5,height:75*5.5}}>   
            <h1 style={{color:"#444"}}>Conducteur</h1>
          </div>
        </div>
      </div>
        <button style={{background:"#333",width:hdinnersize+g*2,margin:`${minmarg}px ${hdmargin}px ${minmarg}px ${hdmargin}px`,height:innersize}}></button>
        <button style={{background:"#333",width:hdinnersize+g*2,margin:`${minmarg}px ${hdmargin}px 5px ${hdmargin}px`,height:innersize}}></button>

        <button className='av squarebtn'></button>
        <button className='av squarebtn'></button>
        <button className='av squarebtn'></button>
        <button className='av squarebtn'></button>
        <button className='av squarebtn'></button>
        <button className='av squarebtn'></button>      </div>
    </div>;
  }
}

export default AVCtrlLayout;