import React from "react";
import MuiAlert from "@mui/material/Alert";

const Alert = ({ severity, message, onClose }) => {
  return (
    <MuiAlert severity={severity} onClose={onClose} sx={{ width: "100%" }}>
      {message}
    </MuiAlert>
  );
};

export default Alert;
