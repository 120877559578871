import React, { Component } from 'react';
import './../avstyle.scss';

class AVPiPSourceSelector extends Component {
  static defaultProps = {
    on_selection_start:null,
    pip_destinations:null,
    selection_active:false,
    selectedPiP:1
  }
  
  constructor(props) {
    super(props);
    this.state = {
      selectedOverlay:1,
    }
}


  onClick(e){
    if(this.props.suspend_waiting)
      this.props.onClick_suspend_waiting()
    else
      this.props.onClick(e);
  }
  
  hd2viewx(hd,view){
    return hd/1920 * view;
  }
  hd2viewy(hd,view){
    return hd/1080 * view;
  }

  get_color(idx){
    //FIXME use css classes 
    if(this.props.selection_active)
      if(this.state.selectedOverlay ==idx)
        return "white"
      else 
        return "rgb(125, 186, 211)"
    else
        return "#555"
  }

  render() {
    const c = this.props;
    const self = this;

    return <div>
      {Array.from(new Array(8).keys()).map((i)=>{
        return <button className={"av tabbtn small disabled" + ((this.props.selectedPiP == i+1)?" active":"")}  
          // onClick={()=>!this.props.default_selectedPiP && this.setState({selectedPiP:i+1})}
          >
          {i+1}
          </button>
      })}
              <div style={{position:"relative",width:this.hd2viewx(1920,320),height:this.hd2viewy(1080,180), background:"#757575",   borderRadius: 10}}>
                {this.props.pip_destinations[this.props.selectedPiP].sources.map((overlay,i)=>(
                  <button 
                      onClick={
                        ()=>{
                            this.setState({selectedOverlay:i+1})
                            this.props.on_selection_start && this.props.on_selection_start(
                              this.props.selectedPiP,i+1
                          )
                        }
                      }
                      style={{
                      position:"absolute",
                      width:this.hd2viewx(overlay.position.width,320) * ( overlay.crop.x2 - overlay.crop.x1),
                      height:this.hd2viewy(overlay.position.height,180),
                      left:this.hd2viewx(overlay.position.x,320) + this.hd2viewx(overlay.position.width,320) * overlay.crop.x1 ,
                      top:this.hd2viewy(overlay.position.y,180),
                      background:this.get_color(i+1),
                      }}>
                        
                  </button>
                ))}
                
              </div>
    </div>;
  }
}

export default AVPiPSourceSelector; 