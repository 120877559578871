import React, { Component } from 'react';
import { Avatar, Paper, Typography, TextField, Button, Container, List, ListItem, ListItemText, ListItemAvatar } from '@mui/material';
import { useParams } from 'react-router-dom';
import Alert from '../components/alert';

const token = localStorage.getItem('token');

class CaptationDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAlert: false,
            error: '',
            guests: [],
            newGuest: {
                nom_invite: '',
                prenom_invite: '',
                email_invite: '',
                info_invite: '',
                fonction_invite: '',
                image_invite: null
            }
        };
    }

    componentDidMount() {
        this.fetchGuests();
    }

    fetchGuests = async () => {
        const { captationId } = this.props.params;
        try {
            const response = await fetch('/b/get-guests', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            if (!response.ok) {
                throw new Error('Erreur lors de la récupération des invités');
            }
            const data = await response.json();
            this.setState({ guests: data });
        } catch (error) {
            this.setState({ error: error.message || "Erreur de récupération des invités", showAlert: true });
        }
    };

    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState(prevState => ({
            newGuest: {
                ...prevState.newGuest,
                [name]: value
            }
        }));
    };

    handleImageUpload = (event) => {
        this.setState(prevState => ({
            newGuest: {
                ...prevState.newGuest,
                image_invite: event.target.files[0]
            }
        }));
    };

    handleSubmit = async (event) => {
        event.preventDefault();
        const { newGuest } = this.state;
        const { captationId } = this.props.params;
        const formData = new FormData();
        formData.append('nom_invite', newGuest.nom_invite);
        formData.append('prenom_invite', newGuest.prenom_invite);
        formData.append('email_invite', newGuest.email_invite);
        formData.append('info_invite', newGuest.info_invite);
        formData.append('fonction_invite', newGuest.fonction_invite);
        formData.append('image_invite', newGuest.image_invite);
        formData.append('captation_id', captationId);
        try {
            const response = await fetch('/b/add-guest', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: formData
            });
            if (!response.ok) {
                throw new Error('Erreur lors de l\'ajout de l\'invité');
            }
            const data = await response.json();
            this.fetchGuests();
        } catch (error) {
            this.setState({ error: error.message || "Erreur d'ajout de l'invité", showAlert: true });
        }
    };

    render() {
        const { showAlert, error, guests, newGuest } = this.state;

        return (
            <Container maxWidth="sm">
                <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
                    <Typography variant="h4" align="center">Création d'un invité</Typography>
                    <form onSubmit={this.handleSubmit}>
                        <TextField
                            label="Nom"
                            name="nom_invite"
                            value={newGuest.nom_invite}
                            onChange={this.handleInputChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Prénom"
                            name="prenom_invite"
                            value={newGuest.prenom_invite}
                            onChange={this.handleInputChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Email"
                            name="email_invite"
                            value={newGuest.email_invite}
                            onChange={this.handleInputChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Information supplémentaire"
                            name="info_invite"
                            value={newGuest.info_invite}
                            onChange={this.handleInputChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Fonction"
                            name="fonction_invite"
                            value={newGuest.fonction_invite}
                            onChange={this.handleInputChange}
                            fullWidth
                            margin="normal"
                        />
                        <input
                            type="file"
                            name="image_invite"
                            onChange={this.handleImageUpload}
                            style={{ margin: '20px 0' }}
                            accept="image/*"
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                        >
                            Ajouter l'invité
                        </Button>
                    </form>
                </Paper>
                {/* Affichage de l'alerte en cas d'erreur */}
                {showAlert && <Alert severity="error" message={error} onClose={() => this.setState({ showAlert: false })} />}
                <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
                    <Typography variant="h4" align="center">Liste des invités</Typography>
                    <List>
                        {guests.map((guest, index) => (
                            <ListItem key={index}>
                                <ListItemAvatar>
                                    <Avatar src={`data:image/jpeg;base64,${guest.photo}`} alt={`${guest.nom} ${guest.prenom}`} />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={`${guest.nom} ${guest.prenom}`}
                                    secondary={guest.email}
                                />
                                <ListItemText
                                    primary={guest.fonction}
                                    secondary={guest.info}
                                />
                            </ListItem>
                        ))}
                    </List>
                </Paper>
            </Container>
        );
    }
}

// Exporter un composant enveloppé pour injecter les paramètres de l'URL
const CaptationDetailsWithRouter = (props) => {
    const params = useParams();
    return <CaptationDetails {...props} params={params} />;
};

export default CaptationDetailsWithRouter;
