import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from '../theme'; // Assurez-vous que le chemin d'accès est correct
import WebFont from 'webfontloader';

import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import MailIcon from '@mui/icons-material/Mail';
import { Link } from 'react-router-dom';

import Chapter from './Chapter';

import {
    Button,
    Switch,
    FormControlLabel,
    Chip,
    FormControl,
  } from "@mui/material";


  const token = localStorage.getItem('token');


class ChapterList extends React.Component {  
  static defaultProps = {
    captation: null,
    guests:    [],
    sequence: null
  }

  state = {
    chapters:  this.props.chapters,
  }
  onChapterDeleted = (deletedChapterId) => { // Méthode pour supprimer une séquence
    this.setState({
        chapters: this.state.chapters.filter(chapter => chapter._id !== deletedChapterId)
    });
  }


  async componentWillMount(){
    await this.fetchChapter(this.props.sequence._id);
  }

  async fetchChapter(sequenceId) {
    this.setState({loading: true});
    try {
      const response = await fetch(`/b/chapters-for-sequence/${sequenceId}`,{
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      if (!response.ok)
        throw new Error("Erreur lors de la récupération des séquences");
      const data = await response.json();
      this.setState({chapters: data, loading:false});
    } catch (err) {
      this.setState({error: err.message, loading:false});
    } 
  }
    
  async addChapter(e) {
    e.preventDefault();
    try {
      const response = await fetch(`/b/add-chapter/${this.props.captation}/${this.props.sequence._id}`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        
      });
      if (!response.ok)
        throw new Error("Erreur lors de l'ajout de la séquence");
      const data = await response.json();
      
      this.setState({
        chapters : this.state.chapters.concat([data.chapter])
      })      
    } catch (err) {
      this.state.error = err.message ;
    }
  };

  render() {
      let ret = <div>
        <h1>Chapitres</h1>
      {this.state.chapters.length > 0 ? (
        this.state.chapters.map((chapter, index) => (
          <Chapter
          key={chapter._id} 
          captation={this.props.captation}
          sequence={this.props.sequence}
          chapter={chapter}
          interventions={this.props.interventions}
          index={index}
          editable={this.props.editable}
          captationId={this.props.captationId}
          onChapterDeleted={this.onChapterDeleted} // Appelle la méthode onSequenceDeleted de SequenceList avec l'ID de la séquence supprimée

        />            
        ))
      ) : (
        <p>Pas de chapitre pour cette sequence.</p>
      )}
      </div>;

      return <div> {ret}
      <Button size="large" variant="contained" color="primary" onClick={ (e) => this.addChapter(e)} style={{ marginTop: "2em" }}>
        Ajouter un chapitre
      </Button>
      </div>;
  }
}
  

export default ChapterList;
