import React, { Component } from 'react';

class AVVuMeter extends Component {
  static defaultProps = {
    input: "master",
    function: "",
    value: "",
    label:"",
    left:0,
    top:0,
    width:65,
    height:65,
    orientation:"vertical",
    F1:0.0,
    F2:0.0,
    style:{}
  }

  constructor(props) {
    super(props);
  }

  naive_log(v,max){
    return Math.floor(Math.log(1+ (Math.E-1) * Math.pow(v,0.4)) * max); 
  }

  render() {
    const { onClick, children } = this.props;
    const c = this.props;
    if (this.props.orientation == "horizontal")
      return (
        <div style={{
          left:this.props.left, 
          top:this.props.top, 
          background:"#111",
          height:10, 
          width:this.props.width,
          ...this.props.style}}>  
            
            <div style={{
              position:"absolute",
              left:0, 
              top:0, 
              transition:"width 0.5s cubic-bezier(.29,1.01,1,-0.68)", 
              background:"linear-gradient(0.25turn, green 50px, orange 75px, red)", 
              height:5, 
              width:this.naive_log(this.props.F1, this.props.width)}}>
            </div>
              
            <div style={{
              position:"absolute",
              left:0,
              top:5,
              transition:"width 0.5s cubic-bezier(.29,1.01,1,-0.68)", 
              background:"linear-gradient(0.25turn, green 50px, orange 75px, red)", 
              height:5, 
              width:this.naive_log(this.props.F2,this.props.width)}}>
            </div> 
              
        </div>
    );
    else{
      return (
        <div style={{
          left:this.props.left, 
          top:this.props.top, 
          background:"#111",
          height:this.props.height, 
          width:10,
          ...this.props.style}}>  
            
            <div style={{
              left:0, 
              bottom:0, 
              transition:"height 0.5s cubic-bezier(.29,1.01,1,-0.68)", 
              background:"linear-gradient(1turn, green 50px, orange 75px, red)", 
              position:"absolute",
              height:this.naive_log(this.props.F1,this.props.height), 
              width:5}}
              >
            </div>
              
            <div style={{
              left:5,
              bottom:0,
              transition:"height 0.5s cubic-bezier(.29,1.01,1,-0.68)", 
              background:"linear-gradient(1turn, green 50px, orange 75px, red)",  
              position:"absolute",
              height:this.naive_log(this.props.F2,this.props.height), 
              width:5
              }}>
            </div> 
              
        </div>
    );
    }
  }
}

export default AVVuMeter; 