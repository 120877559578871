import React, { useState, useEffect } from 'react';
import { Button, Container, Select, TextField, Typography, MenuItem } from '@mui/material';

const CaptationRole = ({ captationId }) => {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState('');

  const [captation, setCaptation] = useState({
    contact_evenement_client: '',
    contact_responsable_internet: '',
    organisator: [],
    participants: [],
    director: '',
    comptable: ''
  });

  useEffect(() => {
    fetchUsers();
    fetchCaptation();
  }, []);

  const fetchUsers = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch('/b/get-users', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setUsers(data);
      } else {
        setError('Erreur lors de la récupération des utilisateurs.');
      }
    } catch (error) {
      setError('Erreur lors de la connexion au serveur.');
    }
  };

  const fetchCaptation = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`/b/get-recording/${captationId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setCaptation(data);
      } else {
        setError('Erreur lors de la récupération de la captation.');
      }
    } catch (error) {
      setError('Erreur lors de la connexion au serveur.');
    }
  };

  const updateCaptation = async (updatedCaptation) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`/b/update-recording-role/${captationId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(updatedCaptation)
      });
      if (!response.ok) {
        setError('Erreur lors de la mise à jour de la captation.');
      }
    } catch (error) {
      setError('Erreur lors de la connexion au serveur.');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedCaptation = {
      ...captation,
      [name]: value
    };
    setCaptation(updatedCaptation);
    updateCaptation(updatedCaptation);
  };

  const handleMultiChange = (e) => {
    const { name, value } = e.target;
    const updatedCaptation = {
      ...captation,
      [name]: typeof value === 'string' ? value.split(',') : value
    };
    setCaptation(updatedCaptation);
    updateCaptation(updatedCaptation);
  };

  return (
    <Container>
      <Typography variant="h6">Captation Roles</Typography>
      <TextField
        select
        label="Contact événement client"
        value={captation.contact_evenement_client || ''}
        name="contact_evenement_client"
        onChange={handleChange}
        margin="normal"
        fullWidth
      >
        {users.map((user) => (
          <MenuItem key={user._id} value={user._id}>
            {user.name} - {user.email}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        select
        label="Contact responsable internet"
        value={captation.contact_responsable_internet || ''}
        name="contact_responsable_internet"
        onChange={handleChange}
        margin="normal"
        fullWidth
      >
        {users.map((user) => (
          <MenuItem key={user._id} value={user._id}>
            {user.name} - {user.email}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        select
        label="Organisateur"
        value={captation.organisator || []}
        name="organisator"
        onChange={handleMultiChange}
        margin="normal"
        fullWidth
        SelectProps={{
          multiple: true
        }}
      >
        {users.map((user) => (
          <MenuItem key={user._id} value={user._id}>
            {user.name} - {user.email}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        select
        label="Participants"
        value={captation.participants || []}
        name="participants"
        onChange={handleMultiChange}
        margin="normal"
        fullWidth
        SelectProps={{
          multiple: true
        }}
      >
        {users.map((user) => (
          <MenuItem key={user._id} value={user._id}>
            {user.name} - {user.email}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        select
        label="Director"
        value={captation.director || ''}
        name="director"
        onChange={handleChange}
        margin="normal"
        fullWidth
      >
        {users.map((user) => (
          <MenuItem key={user._id} value={user._id}>
            {user.name} - {user.email}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        select
        label="Comptable"
        value={captation.comptable || ''}
        name="comptable"
        onChange={handleChange}
        margin="normal"
        fullWidth
      >
        {users.map((user) => (
          <MenuItem key={user._id} value={user._id}>
            {user.name} - {user.email}
          </MenuItem>
        ))}
      </TextField>

      {error && <Typography color="error">{error}</Typography>}
    </Container>
  );
};

export default CaptationRole;
