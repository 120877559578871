import React, { Component } from 'react';
import './avstyle.scss';

class AVButton extends Component {
  static defaultProps = {
    label:null,
    suspend_waiting:false,
    suspend_waiting_color:"white"
  }
    componentDidMount(){
    }

  onClick(e){
    if(this.props.suspend_waiting)
      this.props.onClick_suspend_waiting()
    else
      this.props.onClick(e);
  }

  render() {
    const c = this.props;
    let state_class = this.props.active?"active":"";
    if(this.props.suspend_waiting)
      state_class = "active " +this.props.suspend_waiting_color;
    else if(this.props.active_color == "red")
      state_class += " red";
    else
      state_class += " green";

    return (
      <div>
        <button
            {...this.props}
            onClick={(e)=>this.onClick(e)}
            className={"av squarebtn " + state_class}
            style={{  opacity:this.props.dummy?0.5:1, }}
            >
            {this.props.label}
        </button>
      </div>
    );
  }
}

export default AVButton; 