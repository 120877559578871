// Dans le composant Captation
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from '../theme'; // Assurez-vous que le chemin d'accès est correct
import WebFont from 'webfontloader';
import Alert from './alert';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import MailIcon from '@mui/icons-material/Mail';
import { Link } from 'react-router-dom';
import ODayTimeField from './ui/ODayTimeField';
import Button from '@mui/material/Button';
import ChapterList from './ChapterList';

import {
    Typography,
    MenuItem,
    InputLabel,
    Select,
    Paper,
    TextField,
    Switch,
    FormControlLabel,
  } from "@mui/material";


const token = localStorage.getItem('token');

class Sequence extends React.Component {  
  static defaultProps = {
    captation: null,
    chapters: [], 

    guests:    [],
    sequence: null
  }

  state = {
    sequence : this.props.sequence,
    error : null,
    showAlert: false,
    record: this.props.captation,

  };

  async componentDidMount() {
    await this.fetchChaptersForSequence();
  }
  componentDidUpdate(prevProps, prevState) {

  
    if (prevState.showAlert !== this.state.showAlert && this.state.showAlert) {
      setTimeout(() => {
        this.setState({ showAlert: false });
      }, 2000); // Disparaître après 2 secondes
    }
  }
  async sync_data(){    
    try {
          const response = await fetch(`/b/update-sequence/${this.state.sequence.captationId}/${this.state.sequence._id}`, {
            method: "POST",
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(this.state.sequence),
          });
          if (!response.ok) {
            throw new Error("Erreur lors de la mise à jour de la séquence");
          }
          else {
            this.setState({ showAlert: true });
          }
        } catch (err) {
          this.setState({ error: err.message });
        }
  }

  
  fetchChaptersForSequence = async () => {
    try {
      const sequenceId = this.props.sequence._id; // Assurez-vous d'avoir l'ID de la séquence correct
      const response = await fetch(`/b/chapter-for-sequence/${sequenceId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      if (!response.ok) throw new Error("Erreur lors de la récupération des chapitres");
      const chapters = await response.json();
      this.setState({ chapters });
    } catch (err) {
      // console.error("Erreur lors de la récupération des chapitres", err);
      this.setState({ error: err.message });
    }
  };

  async on_sequence_attribute_change(event){
    const property = event.target.getAttribute('name');
    const updated_value = {
      ...this.state.sequence,
    };
    updated_value[property] = event.target.value;
    this.setState({
      sequence: updated_value
    },
    this.sync_data);
  }
  async deleteSequence(sequenceId) { 
    try {
        const response = await fetch(`/b/delete-sequence/${sequenceId}`, {
            method: "DELETE",
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
        if (!response.ok) {
            throw new Error("Erreur lors de la suppression de la séquence");
        }
        this.props.onSequenceDeleted(sequenceId);
    } catch (err) {
        console.error(err.message);
    }
}

  async on_sequence_attribute_change_time(value,name){
      const updated_value = {
        ...this.state.sequence,
      };
      
      updated_value[name] = value.hour() * 60 + value.minute();
      this.setState(
        { sequence: updated_value },
        this.sync_data
      );
    ;
  }
  
  render() {

      return  ( 
        <div>
     {this.state.showAlert && (
          <Alert
            severity="success" 
            message="La séquence a été mise à jour avec succès."
            onClose={() => this.setState({ showAlert: false })}
          />
        )}
      {this.props.editable ? (
        <form>
          {
            this.props.index != undefined && (<h2>Séquence {this.props.index + 1}</h2>
          )}
          <TextField
        label="Nom de la séquence"
        name="nom_theme_sequence"
        value={this.state.sequence.nom_theme_sequence}
        onChange={(e)=>this.on_sequence_attribute_change(e)}
        fullWidth
        margin="normal"        
        />
        <ODayTimeField
        label="Heure de la séquence"
          name="heure_sequence"
          value={this.state.sequence.heure_sequence}
          onChange={(value,name)=>this.on_sequence_attribute_change_time(value,name)}
          fullWidth
        />
        <TextField
        label="Durée de la séquence"
        name="duree_fin_sequence"
        value={this.state.sequence.duree_fin_sequence}
        onChange={(e)=>this.on_sequence_attribute_change(e)}
        fullWidth
        margin="normal"
        /> 
        <ODayTimeField
          label="Heure d'arrivée du public"
          name="heure_arrivee_public"
          value={this.state.sequence.heure_arrivee_public}
          onChange={(value,name)=>this.on_sequence_attribute_change_time(value,name)}
          fullWidth
        />
        <TextField
        label="Nom de la salle ou lieu"
        name="nom_salle_lieu"
        value={this.state.sequence.nom_salle_lieu}
        onChange={(e)=>this.on_sequence_attribute_change(e)}
        fullWidth
        margin="normal"

          />
        <InputLabel id="intervention-select-label">Choisir des intervention</InputLabel>
        <Select
        labelId="intervention-select-label"
        id="intervention-select"
        multiple 
        value={this.state.sequence.interventionIds || []}
        name="interventions"
        renderValue={(selected) => selected.map(id => this.props.interventions.find(intervention => intervention === id)?.name || "Inconnu").join(', ')}
        onChange={(e)=>this.on_sequence_attribute_change(e)}
        fullWidth
        variant={this.props.editable ? "outlined" : "standard"}
        >
        {this.props.interventions.map((intervention) => (
        <MenuItem key={intervention.id} value={intervention.id}>
        {intervention.name}
        </MenuItem>
        ))}
        </Select>
        {this.props.record && (
        <FormControlLabel
          control={
            <Switch
              checked={this.state.sequence.is_excluded}
              onChange={(e) =>  this.setState({ sequence: {...this.state.sequence, is_excluded: e.target.checked}}, this.sync_data) }
            />
          }
          label="Exclure de l'enregistrement"
        />
      )}
        <FormControlLabel
        control={
            <Switch
            checked={this.state.question_public}
            name="question_public"
            />
        }
        label="Question public"
      /> 

        <Paper key={this.props.index} elevation={4} style={{ padding: "2em", margin: "2em 0" }}>
          <ChapterList           editable={this.props.editable}  
captation={this.props.sequence.captationId} sequence={this.props.sequence} chapters={this.state.sequence.chapters}/>
        </Paper>
               <Button // Bouton pour supprimer la séquence
          variant="contained"
          color="secondary"
          onClick={() => this.deleteSequence(this.state.sequence._id)}
        >
          Supprimer la séquence
        </Button>
        </form>
       
      ) : ( 
        <Paper
          key={this.props.index}
          elevation={2}
          style={{ padding: "20px", margin: "10px 0" }}
        >
           <div>
            <p>Nom de la séquence: {this.state.sequence.nom_theme_sequence}</p>
            <p>Heure: {this.state.sequence.heure_sequence}</p>
            <p>Durée: {this.state.sequence.duree_fin_sequence}</p>
            <p>Lieu: {this.state.sequence.nom_salle_lieu}</p>
            <Typography variant="subtitle2" style={{ marginTop: "10px" }}>
              Interventions:
            </Typography>
            {Array.isArray(this.state.sequence.interventions) && this.state.sequence.interventions.length > 0 ? (
              this.state.sequence.interventions.map((interventionId) => {
                const intervention = this.props.interventions.find((i) => i.id === interventionId);
                return intervention ? (
                  <div key={interventionId}>Nom: {intervention.name}</div>
                ) : <div key={interventionId}>Intervention inconnue</div>;
              })
            ) : (
              <p>Aucune intervention assignée à cette séquence.</p>
            )}
          </div> 
          

      
        </Paper>

      )}
      </div>
      
      );

 
    

  }
  
}
  

export default Sequence;
