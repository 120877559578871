import React, { useState } from 'react';

import OnshoPageContainer from './OnshoPageContainer';

function Passwordoublie() {
  const [email, setEmail] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/b/request-reset-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: email })
      });

      const data = await response.json();
      // Gérer la réponse ici (par exemple, afficher un message à l'utilisateur)
    } catch (error) {
      console.error('Erreur lors de l\'envoi de l\'e-mail:', error);
      // Gérer l'erreur ici
    }
  };

  return (
    <OnshoPageContainer open={false}>
      <form onSubmit={handleSubmit}>
        <input 
          type="email" 
          value={email} 
          onChange={handleEmailChange} 
          placeholder="Entrez votre adresse e-mail"
        />
        <button type="submit">Valider</button>
      </form>
    </OnshoPageContainer>
  );
}

export default Passwordoublie;
