
import React, { Component } from 'react';

class AVFader extends Component {
    static defaultProps = {
        label:"",
        onChange:null,
        start_value: 0
    }

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount(){ }
    
    avfader_change(event) {
        this.setState({dragging_value:event.target.value})
        if(this.props.onChange)
            this.props.onChange(event.target.value);
    }

    handleMouseDown(event) {
        let dragging = true;

        const handleMouseMove = (event) => {
            if (dragging) {
            }
        };

        const handleMouseUp = () => {
            dragging = false;
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };

        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);
    }

    render(){
        const min = 0;
        const max =100;
        return (
            <div className="avfader-container ">
                <span className="avfader-label">{this.props.label}</span>
                <input
                    type="range"
                    orientation="vertical"
                    min={min}
                    max={max}
                    value={this.props.value}
                    onChange={(event)=>this.avfader_change(event)}
                    onMouseDown={(event)=>this.handleMouseDown(event)}
                    className="avfader-input vertical"
                />
                <span className="av avfader-value">{0}</span>
            </div>
        );
    };
};

export default AVFader;
