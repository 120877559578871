import React from 'react';
import AVButton from '../components/ui/av/AVButton';
import AVInputCombo from '../components/ui/av/controllers/AVInputCombo';
import AVFaderCombo from '../components/ui/av/controllers/AVFaderCombo';
import AVVuMeter from '../components/ui/av/AVVuMeter';
import AVFader from '../components/ui/av/AVFader';

const INPUT_DUMMY = {
  preview:false,
  active:false,
  meterF1:0,
  meterF2:0,
  muted:true,
  dummy:true,
}

const styles = {
  panel:{
    padding:5,
    margin:5,
    backgroundColor:"#333",
    height:"fit-content"
  },
  hbox:{
    display:"flex",
    flexDirection:"row",
    justifyContent:""
  },
  vbox:{
    display:"flex",
    flexDirection:"column",
    justifyContent:"flex-start"
  },
  root: {
    height: '100vh',
    // backgroundColor: '#444',
    backgroundColor:"gray",
    display: 'flex',
    flexWrap:"wrap", width:"100%", 
  },
  absolute: {
    position: 'absolute',
    padding:"0.2em"
  },
  button: {
    position: 'absolute',
    padding:"0.2em",
    "&:hover": {
      background: "#efefef"
    },
  },
  topButton: {
    top: '20px',
    left: '20px',
    width: '120px',
    height: '50px',
  },
};

class AVCtrlAudience extends React.Component {
  state = {
    error: null,
    onshokitctrl_url:"",
    vmixstatus:null,
    realstatus:null,
    sync_guests_from_pa:false,
    sync_guests_from_pgm:false,
    sync_pcs_from_pa:false,
    sync_pcs_from_pgm:false,
  };

  async componentDidMount(){
    const interval = async () => {
        const query = `${this.state.onshokitctrl_url}/real`;
        const response = await fetch(query);
        if(response.ok){
          const data = await response.json();
          this.setState({
            vmixstatus:data.vmix,
            realstatus:data
          })
          setTimeout(interval, 300);
      }
      else{
        console.log("error getting "+ query)
      }
    }
    interval()
  }
  
  async api_call(path, data){
    const query = `${this.state.onshokitctrl_url}${path}`;
    console.log(query);
    await fetch(query,{
      method: "POST",
      headers: {"Content-Type": "application/json"},
     body: JSON.stringify(data), // body data type must match "Content-Type" header
    });
  }

  get_input(selector){
    return this.state.vmixstatus.inputs.input.find(i=>i.number === selector) ||
    this.state.vmixstatus.inputs.input.find(i=>i.shortTitle === selector) ||
    this.state.vmixstatus.inputs.input.find(i=>i.title === selector) ||
    this.state.vmixstatus.inputs.input.find(i=>i.key === selector) || 
    {...INPUT_DUMMY};
  }
  
  slider_change(event) {
    console.log("change", event.target.value);
  }

  handleMouseDown(event) {
    let dragging = true;
    console.log("down", event.target.value);

    const handleMouseMove = (event) => {
      if (dragging) {
        console.log("dragging", event.target.value);
      }
    };

    const handleMouseUp = () => {
      dragging = false;
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  }

  render() {
    if(this.state.vmixstatus == null)
      return <div>vmix not ready</div>;

    const guests = Array.from(new Array(3).keys()).map((i)=>this.get_input(`Guest ${i+1}`));
    // console.log(guests)
    const PCs = Array.from(new Array(3).keys()).map((i)=>this.get_input(`PC ${i+1}`));

    return <div style={styles.root}>
      <div style={{ ...styles.hbox, flexWrap:"wrap", width:"100%", backgroundColor:"gray"}}>
        <div id="director-col1" style={{ ...styles.vbox}}> 
          <span style={styles.panel}>
            <div style={styles.hbox}>
              {PCs.map((pc,i)=>(
                <AVInputCombo
                  input_data = {pc}
                  label={`PC ${i+1}`}
                  onshokitctrl_url={this.state.onshokitctrl_url}
                  input={`PC ${i+1}`}
                />
              ))}
            </div>
          </span>
          <span style={styles.panel}>
            <div style={styles.hbox}>
              {guests.map((guest,i)=>(
                <AVInputCombo
                  input_data = {guest}
                  label={`Guest ${i+1}`}
                  onshokitctrl_url={this.state.onshokitctrl_url}
                  width={60*16/9} height={60}
                />
              ))}
            </div>
          </span>
          <div  style={styles.panel}>
            <AVButton
              label="Medias"
              onClick={(e)=>this.api_call("/real/destinations/audience",{source:"medias"})}
              // active={this.state.realstatus.active === camera.number}
              active_color={"red"}
              // dummy={camera.dummy}
            />
          </div>
        </div>
        
        <div style={styles.panel}>
        <div style={styles.hbox}>
            <h6>Audience</h6>
              <AVFaderCombo label="Micros" 
                audio_destination="audience"
                audio_source="micros"
                muted={this.state.realstatus.avctrl.audio_destinations.audience.micros.muted}
                onshokitctrl_url={this.state.onshokitctrl_url} />
              <AVFaderCombo label="Médias" 
                audio_destination="audience"
                audio_source="medias"
                muted={this.state.realstatus.avctrl.audio_destinations.audience.medias.muted}
                onshokitctrl_url={this.state.onshokitctrl_url} />
              <AVFaderCombo label="Musique" 
                audio_destination="audience"
                audio_source="music"
                muted={this.state.realstatus.avctrl.audio_destinations.audience.music.muted}
                onshokitctrl_url={this.state.onshokitctrl_url} />
              <div style={{...styles.vbox}}>
                <div style={{...styles.hbox,border:"solid 1px"}}>
                  <AVFader label="Guest 1"/>
                  <AVFaderCombo label="Guest 2" 
                    audio_destination="audience"
                    audio_source="guest2"
                    muted={this.state.realstatus.avctrl.audio_destinations.audience.guest2.muted}
                    onshokitctrl_url={this.state.onshokitctrl_url}
                  />
                  <AVFader label="Guest 3"/>
                </div>
                <button style={{backgroundColor:this.state.sync_guests_from_pgm?"red":"gray", margin:"2px 40px"}}>sync with PGM</button>
              </div>
              <div style={{...styles.vbox}}>
                <div style={{...styles.hbox,marginLeft:3,border:"solid 1px"}}>
                  <AVFader label="PC 1"/>
                  <AVFader label="PC 2"/>
                  <AVFaderCombo label="PC 3" 
                    audio_destination="audience"
                    audio_source="pc3"
                    muted={this.state.realstatus.avctrl.audio_destinations.audience.pc3.muted}
                    onshokitctrl_url={this.state.onshokitctrl_url}
                  />
                </div>
                <button style={{backgroundColor:this.state.sync_pcs_from_pgm?"red":"gray", margin:"2px 40px"}}>sync with PGM</button>
              </div>
            </div>
        <span style={styles.panel} >
          <div style={{display:"flex"}}>
            <AVVuMeter
                F1={this.state.vmixstatus.audio.master.meterF1}
                F2={this.state.vmixstatus.audio.master.meterF2} />
            <img  alt="snapshot" style={{height:65}}
              // src={`${this.state.onshokitctrl_url}/real/inputs/input/1/snapshot`}
            ></img> 
          </div>
        </span>
        </div>
        </div>
        </div>;
  }
}

export default AVCtrlAudience;