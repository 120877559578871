import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import {
  Button
} from "@mui/material";

 export default function PageTest() {
  const [value, setValue] = React.useState('one');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const sendPostRequest = async () => {
    const postData = {
      casparServer: "OVERLAY",
      casparChannel: "1",
      casparLayer: "20",
      webplayoutLayer: "20",
      relativeTemplatePath: "softpix/Template_Pack_1.1/INFO_RIGHT.html",
      command: "play",
      DataFields: [
        { field: "f0", value: "Here we go again" },
        { field: "f99", value: "./themes/default.css" }
      ]
    };

    try {
      const response = await fetch("http://localhost:5656/api/v1/directplayout", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(postData)
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Post request successful:", data);
      } else {
        console.error('Erreur lors de la requête POST:', response.status);
      }
    } catch (error) {
      console.error('Erreur lors de la connexion au serveur:', error);
    }
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="secondary tabs example"
      >
        <Tab value="one" label="Item One" />
        <Tab value="two" label="Item Two" />
        <Tab value="three" label="Item Three" />
        <Button variant="contained" color="primary" onClick={() => this.sendPostRequest()}>Send POST Request</Button>

      </Tabs>
    </Box>
  );
}