import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from '../theme'; // Assurez-vous que le chemin d'accès est correct
import WebFont from 'webfontloader';

import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import MailIcon from '@mui/icons-material/Mail';
import { Link } from 'react-router-dom';

import Sequence from './Sequence';

import {
    Alert,
    Box,
    Button,
    Chip,
    Container,
    FormControlLabel,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Switch,
    Tab,
    Tabs,
    TextField,
    Typography,
  } from "@mui/material";


  const token = localStorage.getItem('token');


class SequenceList extends React.Component {  
  static defaultProps = {
    captation: null,
    guests:    [],
  }

  state = {
    sequences:  this.props.sequences,
  }
  onSequenceDeleted = (deletedSequenceId) => { // Méthode pour supprimer une séquence
    this.setState({
        sequences: this.state.sequences.filter(sequence => sequence._id !== deletedSequenceId)
    });
}

  async addSequence(e) {
    e.preventDefault();
    try {
      const response = await fetch(`/b/add-sequence/${this.props.captation._id}`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Assurez-vous que votre endpoint nécessite une authentification
        },        
      });
      if (!response.ok)
        throw new Error("Erreur lors de l'ajout de la séquence");
      const data = await response.json();
      this.setState({
        sequences : this.state.sequences.concat([data.sequence])
      })      

    } catch (err) {
          this.state.error = err.message ;
    }
    
  };

  render() {
      let ret = <div>
      {this.state.error && <Alert severity="error">{this.state.error}</Alert>}
      {this.state.loading && <Alert severity="info">"Chargement de ma captation..."</Alert>}
      {this.state.info && <Alert severity="info">{this.state.info}</Alert>}

      {this.state.sequences.length > 0 ? (
        this.state.sequences.map((sequence, index) => (

          <Sequence
          key={sequence._id}
          sequence={sequence}
          sequenceId={sequence._id}
          interventions={this.props.interventions}
          index={index}
          editable={this.props.editable}
          captationId={this.props.captationId}
          onSequenceDeleted={this.onSequenceDeleted}
          record={this.props.captation.record}
        />           
        ))
      ) : (
        <p>Pas de séquences pour cette captation.</p>
      )}
      </div>;

       
        return <div> 
          
          {ret}
          <Button size="large" variant="contained" color="primary" style={{ marginTop: "2em" }} 
          onClick={ (e) => this.addSequence(e)}>
            Ajouter une séquence
          </Button>

        </div>;
  }
}
  

export default SequenceList;
