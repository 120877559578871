import React from 'react';
import dayjs from 'dayjs';

import { TimeField } from '@mui/x-date-pickers/TimeField';


class ODayTimeField extends React.Component {  
  static defaultProps = {
    value:null,
    onChange:null,
    name:null,
    label:null
 }

 constructor(props) {
    super(props)
}
    
  async on_change_time(value){
    if(value != null && value.isValid()){
        this.props.onChange(value,this.props.name)
    }
  }
  
  render() {
      return  <div>
        <TimeField
        {...this.props} 
        label={this.props.label}
        value={dayjs({ hour:(this.props.value /60), minute:this.props.value % 60 })}
        onChange={(value,c)=>this.on_change_time(value)}
        format="HH:mm"
        />
    </div>;
  }
}
  

export default ODayTimeField;


