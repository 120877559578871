import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { theme } from '../theme'; 
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { styled, alpha } from '@mui/material/styles';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { TreeItem, treeItemClasses } from '@mui/x-tree-view/TreeItem';
import OnshoPageContainer from './OnshoPageContainer';

function Home() {
  const [error, setError] = useState('');
  const [users, setUsers] = useState([]); 
  const [captations, setCaptations] = useState([]); 
  const token = localStorage.getItem('token');
  const userid = localStorage.getItem('userId');
  useEffect(() => {
  }, []); 
  const StyledTreeItem = styled(TreeItem)(({ theme }) => ({
    color:
      theme.palette.mode === 'light'
        ? theme.palette.grey[800]
        : theme.palette.grey[200],
    [`& .${treeItemClasses.content}`]: {
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(0.5, 1),
      margin: theme.spacing(0.2, 0),
      [`& .${treeItemClasses.label}`]: {
        fontSize: '0.8rem',
        fontWeight: 500,
      },
    },
    [`& .${treeItemClasses.iconContainer}`]: {
      borderRadius: '50%',
      backgroundColor:
        theme.palette.mode === 'light'
          ? alpha(theme.palette.primary.main, 0.25)
          : theme.palette.primary.dark,
      color: theme.palette.mode === 'dark' && theme.palette.primary.contrastText,
      padding: theme.spacing(0, 1.2),
    },
    [`& .${treeItemClasses.groupTransition}`]: {
      marginLeft: 15,
      paddingLeft: 18,
      borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
  }));
  const removeCaptationByName = async (captationName) => {
    const token = localStorage.getItem('token'); 
    if (!token) {
      setError('Aucun token JWT trouvé.');
      return;
    }
  
    const response = await fetch('/b/remove-captation-by-name', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({ captation_name: captationName }),
    });
  
    if (response.ok) {
      fetchCaptations(); 
    } else {
      setError('Erreur lors de la suppression de la captation.');
    }
  };
  const fetchCaptations = async (userid) => {
    try {
      const response = await fetch(`/b/treeview/${userid}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });
      if (response.ok) {
        const data = await response.json();
        if (Array.isArray(data)) {
          setCaptations(data.map(captation => ({
            label: captation.captation.nomcaptation,
            autre_info : captation.captation.autres_informations_utiles,
            localisation : captation.captation.locationcaptation,
            datecaptation : captation.captation.datecaptation,
            typecaptation : captation.captation.typecaptation,
            duration : captation.captation.duration,
            record : captation.captation.enregistrement,
            children: captation.sequences ? captation.sequences.map(sequence => ({ label: sequence })) : []
          })));
        } else {
          setError('Données de captation invalides');
        }
      } else {
        setError('Erreur lors de la récupération des captations');
      }
    } catch (error) {
      setError('Erreur lors de la connexion au serveur');
    }
  };
  const handleSeeCaptation = (captationId) => {
    // Fonction pour afficher l'ID de la captation dans la console
  };

  const MUI_X_PRODUCTS = captations.map((captation, captationIndex) => ({
    id: captationIndex + 1,
    label: captation.label,
    children: captation.children ? captation.children.map((child, childIndex) => ({
        id: `${captationIndex + 1}-${childIndex + 1}`,
        label: child.label,
    })) : [],
  }));
  const MUI_X_PRODUCTS_test = captations.map((captation, captationIndex) => ({
    id: `captation-${captationIndex + 1}`,
    label: captation.label,
    children:  [
      { 
        id: `captation-${captationIndex + 1}-location`, 
        label: `Localisation: ${captation.localisation}`,

      },
      { 
        id: `captation-${captationIndex + 1}-datecaptation`, 
        label: `Date de captation: ${captation.datecaptation}` 
      },
      { 
        id: `captation-${captationIndex + 1}-typecaptation`, 
        label: `Type de captation: ${captation.typecaptation}` 
      },
      { 
        id: `captation-${captationIndex + 1}-duration`, 
        label: `Durée: ${captation.duration} minutes` 
      },
      { 
        id: `captation-${captationIndex + 1}-record`, 
        label: `Enregistrement: ${captation.record ? 'Oui' : 'Non'}` 
      },
      { 
        id: `captation-${captationIndex + 1}-participants`, 
        label: `Participants: ${captation.participants ? captation.participants.join(', ') : 'Aucun participant'}` 
      },
 
      captation.children.length > 0 ? { 
        id: `captation-${captationIndex + 1}-sequences`,
        label: "Séquences",
        children: captation.children.map((child, childIndex) => ({
          id: `captation-${captationIndex + 1}-sequence-${childIndex + 1}`, 
          label: child.label,
        })),
      } : null,
    ].filter(Boolean) 
  }));

let cpt = 0;
  const test =async () => {
cpt++;
  }

  
  return (
    <OnshoPageContainer>
    <Container component="main" maxWidth="md">
      <Button variant="contained" color="primary" onClick={() => fetchCaptations(userid)}>Charger les captations</Button>
      {error && <p>{error}</p>}
      <h1 color="">Test Onsho</h1>
      <p color="primary">test </p>
      <RichTreeView  aria-label="customized"
  defaultExpandedItems={['1']}  sx={{ overflowX: 'hidden', minHeight: 270, flexGrow: 1, maxWidth: 300 }}
  slots={{ item: StyledTreeItem }} items={MUI_X_PRODUCTS_test}  onClick={() =>test()} />

    </Container>
    </OnshoPageContainer>
  );
}

export default Home;
