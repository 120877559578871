import React, { useState } from 'react';
import {
  TextField, Button, Paper, Typography, Container
} from '@mui/material';
import { Link } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { theme } from '../theme'; 


import OnshoPageContainer from './OnshoPageContainer';


function RegisterForm() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [name, setName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    
    const response = await fetch('/b/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username, password, email, name, dateOfBirth
    }),
    });

    const data = await response.json();
    if (response.ok) {
      window.location.href = '/login'; // Redirection vers la page de connexion
    } else {
      setError(data.msg || 'Erreur lors de l’inscription');
    }
  };

  return (
    <OnshoPageContainer component="main" >
      <Paper elevation={6} style={{ padding: 20 }}>
        <Typography component="h1" variant="h5">
          INSCRIPTION
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Nom d'utilisateur"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Email"
            type="email"
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Mot de passe"
            type="password"
            autoComplete="new-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextField
    variant="outlined"
    margin="normal"
    required
    fullWidth
    label="Nom complet"
    value={name}
    onChange={(e) => setName(e.target.value)}
/>
<TextField
    variant="outlined"
    margin="normal"
    required
    fullWidth
    label="Date de naissance"
    type="date"
    InputLabelProps={{ shrink: true }}
    value={dateOfBirth}
    onChange={(e) => setDateOfBirth(e.target.value)}
/>

          {error && <Typography color="error">{error}</Typography>}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            S'inscrire
          </Button>
        </form>
      </Paper>
      <Link to="/login" fullWidth variant="contained" color="secondary">Deja inscrit ?</Link>
    </OnshoPageContainer>
  );
}

export default RegisterForm;
