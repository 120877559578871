import React, { useState } from "react";
import { TextField, Button, Paper, Typography, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Alert from "../components/alert";
import OnshoPageContainer from "../page/OnshoPageContainer";

function LoginForm() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch("/b/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          username: username,
          password: password,
        }),
      });

      const data = await response.json();
   
      if (response.ok) {
        localStorage.setItem("token", data.access_token);
        localStorage.setItem(
          "userInfo",
          JSON.stringify({ username, email: data.email })
        );
        localStorage.setItem("userId", data.userid);
        navigate("/home");
      }
      else{
        setError(data.msg || "Erreur de connexion");
        setShowAlert(true);
      }
    } catch (error) {
      setError(error.message || "Erreur de connexion");
      setShowAlert(true);
    }
  };

  const handleForgotPassword = async () => {
    const email = prompt(
      "Entrez votre adresse email pour réinitialiser votre mot de passe:"
    );
    if (email) {
      const response = await fetch("/b/request-reset-password", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();
      if (response.ok) {
        alert("Un email de réinitialisation a été envoyé.");
      } else {
        alert(data.msg || "Erreur lors de l'envoi de l'email");
      }
    }
  };

  return (
    <OnshoPageContainer component="main">
      <Paper elevation={6} style={{ padding: 20 }}>
        <Typography component="h1" variant="h5">
          Connexion
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Nom d'utilisateur"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Mot de passe"
            type="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button type="submit" fullWidth variant="contained" color="primary">
            Se connecter
          </Button>
        </form>
      </Paper>
      <Button
        variant="contained"
        color="primary"
        onClick={handleForgotPassword}
      >
        Mot de passe oublié
      </Button>
      {/* Afficher l'alerte en cas d'erreur */}
      {showAlert && (
        <Alert
          severity="error"
          message={error}
          onClose={() => setShowAlert(false)}
        />
      )}
    </OnshoPageContainer>
  );
}

export default LoginForm;
